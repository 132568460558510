import React, { useState, useRef, useEffect, useCallback } from "react";
import PageLayout from "../pagelayout/pagelayout";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/actions/userAction";
import "./bookEventAdmin.css";
import { MultiSelect } from "react-multi-select-component";
import DataTable from "react-data-table-component";
import search from "../../assets/images/search.svg";
import AddGamesBookEvent from "../modal/addGamesBookEvent";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import { getAllPaymentForAdmin, verifyOfflinePayment } from "../../redux/actions/paymentApiActions";
import useDebouncedSearch from "../../helpers/debounce";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { failureAlert, IsAdmin, successAlert, Frontned_URL } from "../../helpers/helper";
import {
  S3_BASE_URL,
  BASE_URL,
  GOOGLE_RECAPTCHA_SITE_KEY,
  axiosApiInstance,
  encryptData,
  decryptData
} from "../../helpers/helper";

import LoadingComponent from "../loader/LoadingComponent";
import { SYMBOLS } from "../../constants";
import { log } from "synonyms/dictionary";
import { uploadFile } from '../../redux/actions/commonActions';
import Select from "react-select";
import deleteIcon from '../../assets/images/delete.svg';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ReactSlider from 'react-slider';

import debounce from 'lodash.debounce';
import BookEventReqs from "./bookEventReq";
import EventsQuestions from "./eventQuestions";
const localizer = momentLocalizer(moment);
const BookEventAdmin = (props) => {
  const [events, setEvents] = useState([]);
  const [datesToSend, setDatesToSend] = useState([]);
  const [combineArr, setCombineArr] = useState([]);
  const [eventDates, setEventDates] = useState([]);
  useEffect(() => {
    getAllPlatforms();

  }, []);
  const handleSelectSlot = (slotInfo) => {
    const message = window.prompt('Enter Event Details');
    if (message) {
      const formattedDate = moment(slotInfo.start).format('YYYY-MM-DD');
      const newEvent = {
        title: message,
        start: slotInfo.start,
        end: slotInfo.start,
        allDay: true,
        message,
      };
      setEvents((prevEvents) => [...prevEvents, newEvent]);
      setEventDates((prevDates) => [...prevDates, formattedDate]);

    }
  };

  const handleSelectEvent = (event) => {
    const isRemove = window.confirm(`Message: ${event.title}\nDo you want to remove this event?`);
    if (isRemove) {
      setEvents((prevEvents) => prevEvents.filter(e => e !== event));
    }
  };

  const eventPropGetter = () => {
    return {
      style: {
        backgroundColor: 'lightblue',
        color: 'black',
        border: 'none',
        borderRadius: '5px',
      },
    };
  };

  const handleGetAllDates = async () => {
    const formattedEvents = events.map(event => {
      const date = new Date(event.start);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return {
        title: event.title,
        date: `${year}-${month}-${day}`
      };
    });
    if (formattedEvents.length > 0) {
      setLoaded(false);
      let obj = { blockDates: formattedEvents };
      const result = await axiosApiInstance
        .post(BASE_URL + "/api/bookEvent/addBlockDates", obj)
        .then((response) => {
          successAlert("Dates updated successfully");
          getAllBlockedDates();
          setLoaded(true);
        })
        .catch((error) => {
          failureAlert("Failed to add request");
          setLoaded(true);
        });
    }
  };
  const initialFormData = {
    title: "",
    description: "",
    feature: "",
    minPlayer: "",
    maxPlayer: "",
    duration: "",
    eventCost: "",
    eventCostUSD: "",
    eventType: "",
    categories: [],
    difficultylevel: "",
    gameType: "",
    bookingType: "",
    guaranteeType: "",
    headCount: "",
    howItWorks: "",
    agenda: "",
    thingsYouNeed: "",
    // howToJoin: "",
    breakOutRoom: "",
    breakOutRoomSize: "",
    supportedPlatforms: []
  };
  const [formData, setFormData] = useState(initialFormData);
  const [openBookEventModal, setOpenBookEventModal] = useState(false);


  const [errors, setErrors] = useState({
    title: "",
    description: "",
    feature: "",
    minPlayer: "",
    maxPlayer: "",
    duration: "",
    eventCost: "",
    eventCostUSD: "",
    eventType: "",
    categories: [],
    difficultylevel: "",
    gameType: "",
    bookingType: "",
    guaranteeType: "",
    headCount: "",
    howItWorks: "",
    agenda: "",
    thingsYouNeed: "",
    // howToJoin: "",
    breakOutRoom: "",
    breakOutRoomSize: "",
    supportedPlatforms: []
  });
  const handleTextChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    const name = e.target.name;
    const value = e.target.value;

    if (name == 'headCount') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        maxPlayer: value
      }));
    }
    if (name == 'maxPlayer') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        headCount: value
      }));
    }
 
  };


  const [loaded, setLoaded] = useState(true);
  const validateForm = () => {
   
    let valid = true;
    const newErrors = {
      title: "",
      description: "",
      feature: "",
      minPlayer: "",
      maxPlayer: "",
      duration: "",
      eventCost: "",
      eventCostUSD: "",
      eventType: "",
      categories: [],
      difficultylevel: "",
      gameType: "",
      bookingType: "",
      guaranteeType: "",
      headCount: "",
      howItWorks: "",
      agenda: "",
      thingsYouNeed: "",
      // howToJoin: "",
      breakOutRoom: "",
      breakOutRoomSize: "",
      supportedPlatforms: []

    };

    if (!formData.title) {
      newErrors.title = "Required";
      valid = false;
    }
    if (!formData.description) {
      newErrors.description = "Required";
      valid = false;
    }
    if (!formData.feature) {
      newErrors.feature = "Required";
      valid = false;
    }
    const minPlayer = Number(formData.minPlayer);
    const maxPlayer = Number(formData.maxPlayer);
    
    if (minPlayer === '' || minPlayer < 1 || minPlayer > 10000) {
      newErrors.minPlayer = 'minPlayer must be between 1 and 10000';
      valid = false;
    }
    if (maxPlayer === '' || maxPlayer < 1 || maxPlayer > 10000) {
      newErrors.maxPlayer = 'maxPlayer must be between 1 and 10000';
      valid = false;
    }
    
    // Ensure minPlayer is less than maxPlayer
    if (minPlayer >= maxPlayer) {
      newErrors.minPlayer = 'minPlayer must be less than maxPlayer';
      newErrors.maxPlayer = 'maxPlayer must be greater than minPlayer';
      valid = false;
    }
    if (!formData.duration) {
      newErrors.duration = "Required";
      valid = false;
    }
    if (!formData.eventCost) {
      newErrors.eventCost = "Required";
      valid = false;
    }
    if (!formData.eventCostUSD) {
      newErrors.eventCostUSD = "Required";
      valid = false;
    }
    if (!formData.eventType) {
      newErrors.eventType = "Required";
      valid = false;
    }
    if (formData.categories.length == 0) {
      newErrors.categories = "Required";
      valid = false;
    }
    if (!formData.difficultylevel) {
      newErrors.difficultylevel = "Required";
      valid = false;
    }
    if (!formData.gameType) {
      newErrors.gameType = "Required";
      valid = false;
    }
    if (!formData.bookingType) {
      newErrors.bookingType = " Required";
      valid = false;
    }
    if (!formData.guaranteeType) {
      newErrors.guaranteeType = " Required";
      valid = false;
    }
    if (!formData.headCount) {
      newErrors.headCount = "Required";
      valid = false;
    }
    if (!formData.howItWorks) {
      newErrors.howItWorks = "Required";
      valid = false;
    }

    if (!formData.agenda) {
      newErrors.agenda = "Required";
      valid = false;
    }
    if (!formData.thingsYouNeed) {
      newErrors.thingsYouNeed = "Required";
      valid = false;
    }

    if (!formData.breakOutRoom) {
      newErrors.breakOutRoom = "Required";
      valid = false;
    }

    if (formData.breakOutRoom == 'yes') {
      if (formData.breakOutRoomSize === '' || formData.breakOutRoomSize < 1 || formData.breakOutRoomSize > 10000) {
        newErrors.breakOutRoomSize = 'Size must be between 1 and 10000';
        valid = false;
      }
    }

    if (multiSelectOptionsPlatforms.length == 0 && formData.eventType == 'online') {
      newErrors.supportedPlatforms = "Required";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };
  const [categories, setCategories] = useState([]);
  const [banners, setBanners] = useState([]);
  const isAdmin = IsAdmin();
  const dispatch = useDispatch();
  const [fetch, fetchGames] = useState(false);
  const signOut = async () => {
    await dispatch(logOut());
    if (isAdmin) props.history.push("/admin");
    else props.history.push("/");
  };

  const [bookEventGames, setBookEventGames] = useState([]);
  const [bookEventCategories, setBookEventCategories] = useState([]);
  const [bookEventplatforms, setBookEventPlatforms] = useState([]);
  const [formData2, setFormData2] = useState({
    textInput1: '',
    fileInput: null,
  });
  const [faqsData, setfaqsData] = useState([]);
  const [testimonialData, setTestimonialData] = useState([]);
  const [idsArr, setGamesIdsForCategory] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [categoryTitle, setCategoryTitle] = useState([]);
  const [errors2, setErrors2] = useState({});
  const [AllGames, setAllGamesGames] = useState([]);
  const [AllGamesOnfilter, setFilterGames] = useState([]);
  const [filter, setFilter] = useState('');


  const handleSearch = debounce((value) => {
    setFilter(value);
  }, 300);

  const handleSearchChange = (e) => {
    setLoaded(false);
    handleSearch(e.target.value);
  };

  useEffect(() => {
    if (filter === '') {
      setFilterGames(AllGames);
    } else {
      const filtered = AllGames.filter((game) =>
        game.title.toLowerCase().includes(filter.toLowerCase())
      );
      setFilterGames(filtered);
    }
    setLoaded(true);
  }, [filter, AllGames]);


  const [multiSelectOptions, setMultiSelectOptions] = useState([]);
  const [multiSelectOptionsPlatforms, setMultiSelectOptionsplatforms] = useState([]);
  const dummyBlockedDates = [
    {
      message: "Meeting with client",
      date: "2024-10-09"
    },
    {
      message: "Team outing",
      date: "2024-10-10"
    },
    {
      message: "Project deadline",
      date: "2024-10-12"
    }
  ];


  const getAllCategories = async () => {
    try {
      const response = await axiosApiInstance.get(BASE_URL + "/api/bookEvent/getAllCategories");
      const result = response.data.data;

      setCategories(response.data.data);
      const options = result.map(item => ({
        value: item.id,
        label: item.title,
      }));
      setBookEventCategories(options);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };
  const getAllBlockedDates = async () => {
    try {
      const response = await axiosApiInstance.get(BASE_URL + "/api/bookEvent/getBlockedDates");

      const result = JSON.parse(response?.data?.data[0]?.blockedDates);

      setDatesToSend(result);

      const transformedEvents = result.map(blockedDate => ({
        start: new Date(blockedDate.date),
        end: new Date(blockedDate.date),
        title: blockedDate.title
      }));

      setEvents(transformedEvents);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };
  const getAllBanners = async () => {
    try {
      const response = await axiosApiInstance.get(BASE_URL + "/api/bookEvent/getBookEventBanners");

      setBanners(response.data.data);

    } catch (error) {
      console.log(error)
    }
  };

  const getAllGames = async () => {
    try {
      const response = await axiosApiInstance.get(BASE_URL + "/api/bookEvent/getBookEventAllGames");
      const result = response.data.data;

      setAllGamesGames(result);
      const options = result.map(item => ({
        value: item.id,
        label: item.title,
      }));
      setBookEventGames(options);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const getAllMainImages = async (gameid) => {
    try {
      const response = await axiosApiInstance.get(`${BASE_URL}/api/bookEvent/getAllMainImages/${gameid}`);

      const GamesImages = response.data.data;
      const updatedRows = GamesImages.map(item => ({
        images: [
          `${item.type}`,
          `${item.id}`,
          `${item.desktopBannerPath}`
        ],
        errors: ['', '']
      }));

      setFileOrLinkRows(updatedRows);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const getAllGamesImages = async (gameid) => {
    try {
      const response = await axiosApiInstance.get(`${BASE_URL}/api/bookEvent/getAllGamesImages/${gameid}`);

      const gameInfo = response.data.data;



      const newRows = [...fileOrLinkRows];
      newRows[0].type = checkStringType(gameInfo?.imageOne) === 'image' ? 'file' : 'link';
      newRows[0].value = checkStringType(gameInfo?.imageOne) === 'image' ? '' : gameInfo?.imageOne;
      newRows[0].errors = {};
      newRows[0].image = checkStringType(gameInfo?.imageOne) === 'image' ? gameInfo?.imageOne : null;


      newRows[1].type = checkStringType(gameInfo?.imageTwo) === 'image' ? 'file' : 'link';
      newRows[1].value = checkStringType(gameInfo?.imageTwo) === 'image' ? '' : gameInfo?.imageTwo;
      newRows[1].errors = {};
      newRows[1].image = checkStringType(gameInfo?.imageTwo) === 'image' ? gameInfo?.imageTwo : null;

      newRows[2].type = checkStringType(gameInfo?.imageThree) === 'image' ? 'file' : 'link';
      newRows[2].value = checkStringType(gameInfo?.imageThree) === 'image' ? '' : gameInfo?.imageThree;
      newRows[2].errors = {};
      newRows[2].image = checkStringType(gameInfo?.imageThree) === 'image' ? gameInfo?.imageThree : null;

      newRows[3].type = checkStringType(gameInfo?.imageFour) === 'image' ? 'file' : 'link';
      newRows[3].value = checkStringType(gameInfo?.imageFour) === 'image' ? '' : gameInfo?.imageFour;
      newRows[3].errors = {};
      newRows[3].image = checkStringType(gameInfo?.imageFour) === 'image' ? gameInfo?.imageFour : null;


      newRows[4].type = checkStringType(gameInfo?.imageFive) === 'image' ? 'file' : 'link';
      newRows[4].value = checkStringType(gameInfo?.imageFive) === 'image' ? '' : gameInfo?.imageFive;
      newRows[4].errors = {};
      newRows[4].image = checkStringType(gameInfo?.imageFive) === 'image' ? gameInfo?.imageFive : null;

      setFileOrLinkRows(newRows);

    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };
  useEffect(() => {
    getAllCategories();
    getAllBlockedDates();
    getAllGames();
  }, []);


  const [selectedRowId, setSelectedRowId] = useState(null);
  const [activeTab, setActiveTab] = useState('Categories');
  const [bannerFileToUpload, setBannerFileToUpload] = useState(null);
  const [mobileBannerFileToUpload, setMobileBannerFileToUpload] = useState(null);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab == 'Testimonials') {
      getAllBanners();
    }
  };
  const [selectedBannerImage, setBannerSelectedImage] = useState(null);
  const [selectedMobileBannerImage, setMobileBannerSelectedImage] = useState(null);
  const [bannererror, setBannerError] = useState('');
  const [mobileBannererror, setMobileBannerError] = useState('');
  const [categoryName, setcategoryName] = useState('');
  const handlecategoryNameChange = (e) => {
    const value = e.target.value;
    setcategoryName(value);
    setcategoryNameError(validatecategoryName(value));
  };
  const validatecategoryName = (value) => {
    return value.trim() ? '' : 'Please fill out this field';
  };
  const fileInputRefCategoryImage = useRef(null);
  const fileInputRefGameBanner = useRef(null);
  const InputRefDesktopBanner = useRef(null);
  const InputRefMobileBanner = useRef(null);
  const [fileError, setFileError] = useState('');
  const [gameBannerFileError, setGameBannerFileError] = useState('');
  const [preview, setPreview] = useState('');
  const [gameBannerPreview, setGameBannerPreview] = useState('');
  const [desktopBannerPreview, setBannerPreview] = useState('');
  const [mobileBannerPreview, setMobileBannerPreview] = useState('');
  const [categoryNameError, setcategoryNameError] = useState('');
  const [GameBannerpathOnEdit, setGameBannerPathOnEdit] = useState('');
  const handleSubmitForm = async (e) => {
    e.preventDefault();

    const file = fileInputRefCategoryImage.current.files[0];
    const nameError = validatecategoryName(categoryName);
    setcategoryNameError(nameError);
    const fileError = await validateFile(file);
    setFileError(fileError);
    if (!nameError && !fileError) {
      try {
        const formData = new FormData();
        formData.append("company-logos", file);
        setLoaded(false);
        const response2 = await dispatch(uploadFile(formData));
        let obj = {
          title: categoryName,
          categoryImage: response2.data.data.path
        };
        const result = await axiosApiInstance
          .post(BASE_URL + "/api/bookEvent/addBookEventCategory", obj)
          .then((response) => {

            successAlert("Category added successfully");
            getAllCategories();
            setLoaded(true);
          })
          .catch((error) => {
            failureAlert("Failed to add request");
            setLoaded(true);
          });

        setcategoryName('')
        document.getElementById('categoryImage').value = '';
        setPreview('')
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }
  };



  const handleGameBannerChangeOnEdit = async (e) => {
    const file = e.target.files[0];
    await validateGameBannerFileOnEdit(file);
  };

  const validateGameBannerFileOnEdit = (file) => {
    return new Promise((resolve) => {
      if (!file) {
        setGameBannerFileError('Please select a file.');
        setGameBannerPreview('');
        resolve('Please select a file.');
      } else if (file.size > 10 * 1024 * 1024) {
        setGameBannerFileError('File size should not exceed 10MB.');
        setGameBannerPreview('');
        resolve('File size should not exceed 10MB.');
      } else {
        const img = new Image();
        const url = URL.createObjectURL(file);
        img.onload = async () => {
          if (img.width !== 400 || img.height !== 200) {
            setGameBannerFileError('Image dimensions must be 400 x 200 pixels.');
            setGameBannerPreview('');
            resolve('Image dimensions must be 300 x 300 pixels.');
          } else {

            const result = await uploadImage(file);

            setGameBannerPathOnEdit(result);
            setGameBannerPreview(result)

          }
        };
        img.onerror = () => {
          setGameBannerFileError('Invalid image file.');
          setGameBannerPreview('');
          resolve('Invalid image file.');
        };
        img.src = url;
      }
    });
  };

  const handleGameBannerChange = async (e) => {
    const file = e.target.files[0];
    await validateGameBannerFile(file);
  };

  const validateGameBannerFile = (file) => {
    return new Promise((resolve) => {
      if (!file) {
        setGameBannerFileError('Please select a file.');
        setGameBannerPreview('');
        resolve('Please select a file.');
      } else if (file.size > 10 * 1024 * 1024) {
        setGameBannerFileError('File size should not exceed 10MB.');
        setGameBannerPreview('');
        resolve('File size should not exceed 10MB.');
      } else {
        const img = new Image();
        const url = URL.createObjectURL(file);
        img.onload = () => {
          if (img.width !== 400 || img.height !== 200) {
            setGameBannerFileError('Image dimensions must be 400 x 200 pixels.');
            setGameBannerPreview('');
            resolve('Image dimensions must be 400 x 200 pixels.');
          } else {
            setGameBannerFileError('');
            setGameBannerPreview(url);
            resolve('');
          }
        };
        img.onerror = () => {
          setGameBannerFileError('Invalid image file.');
          setGameBannerPreview('');
          resolve('Invalid image file.');
        };
        img.src = url;
      }
    });
  };


  const handleCategoryFileChange = async (e) => {
    const file = e.target.files[0];
    await validateFile(file);
  };

  const validateFile = (file) => {
    return new Promise((resolve) => {
      if (!file) {
        setFileError('Please select a file.');
        setPreview('');
        resolve('Please select a file.');
      } else if (file.size > 10 * 1024 * 1024) {
        setFileError('File size should not exceed 10MB.');
        setPreview('');
        resolve('File size should not exceed 10MB.');
      } else {
        const img = new Image();
        const url = URL.createObjectURL(file);
        img.onload = () => {
          // if (img.width !== 150 || img.height !== 150) {
          //   setFileError('Image dimensions must be 150 x 150 pixels.');
          //   setPreview('');
          //   resolve('Image dimensions must be 150 x 150 pixels.');
          // } else {
          setFileError('');
          setPreview(url);
          resolve('');
          // }
        };
        img.onerror = () => {
          setFileError('Invalid image file.');
          setPreview('');
          resolve('Invalid image file.');
        };
        img.src = url;
      }
    });
  };

  const handleBannerChange = async (e) => {
    const file = e.target.files[0];
    await validateDesktopBannerFile(file);
  };
  const validateDesktopBannerFile = (file) => {
    return new Promise((resolve) => {
      if (!file) {
        setBannerError('Please select a file.');
        setBannerPreview('');
        resolve('Please select a file.');
      } else if (file.size > 10 * 1024 * 1024) {
        setBannerError('File size should not exceed 10MB.');
        setBannerPreview('');
        resolve('File size should not exceed 10MB.');
      } else {
        const img = new Image();
        const url = URL.createObjectURL(file);
        img.onload = () => {
          // if (img.width !== 1920 || img.height !== 600) {
          //   setBannerError('Image dimensions must be 1920 x 600 pixels.');
          //   setBannerPreview('');
          //   resolve('Image dimensions must be 1920 x 600 pixels.');
          // } else {
          setBannerError('');
          setBannerPreview(url);
          resolve('');
          // }
        };
        img.onerror = () => {
          setBannerError('Invalid image file.');
          setBannerPreview('');
          resolve('Invalid image file.');
        };
        img.src = url;
      }
    });
  };
  const handleMobileBannerChange = async (e) => {
    const file = e.target.files[0];
    await validateMobileBannerFile(file);
  };
  const validateMobileBannerFile = (file) => {
    return new Promise((resolve) => {
      if (!file) {
        setMobileBannerError('Please select a file.');
        setMobileBannerPreview('');
        resolve('Please select a file.');
      } else if (file.size > 10 * 1024 * 1024) {
        setMobileBannerError('File size should not exceed 10MB.');
        setMobileBannerPreview('');
        resolve('File size should not exceed 10MB.');
      } else {
        const img = new Image();
        const url = URL.createObjectURL(file);
        img.onload = () => {
          // if (img.width !== 360 || img.height !== 477) {
          //   setMobileBannerError('Image dimensions must be 360 x 477 pixels.');
          //   setMobileBannerPreview('');
          //   resolve('Image dimensions must be 360 x 477 pixels.');
          // } else {
          setMobileBannerError('');
          setMobileBannerPreview(url);
          resolve('');
          // }
        };
        img.onerror = () => {
          setMobileBannerError('Invalid image file.');
          setMobileBannerPreview('');
          resolve('Invalid image file.');
        };
        img.src = url;
      }
    });
  };

  const handleBannerSubmit = async (event) => {
    event.preventDefault();
    const desktopBannerFile = InputRefDesktopBanner.current.files[0];
    const mobileBannerFile = InputRefMobileBanner.current.files[0];
    const desktopBannerFileError = await validateDesktopBannerFile(desktopBannerFile);
    const mobileBannerFileError = await validateMobileBannerFile(mobileBannerFile);
    setBannerError(desktopBannerFileError);
    setMobileBannerError(mobileBannerFileError);
    if (!desktopBannerFileError && !mobileBannerFileError) {


      try {
        const bannerData = new FormData();
        bannerData.append("company-logos", desktopBannerFile);
        const mobileBannerData = new FormData();
        mobileBannerData.append("company-logos", mobileBannerFile);
        setLoaded(false);
        const desktopBannerUploadResponse = await dispatch(uploadFile(bannerData));
        const mobileBannerUploadResponse = await dispatch(uploadFile(mobileBannerData));
        let obj = {
          desktopBannerPath: desktopBannerUploadResponse?.data?.data?.path,
          mobileBannerPath: mobileBannerUploadResponse?.data?.data?.path
        };


        await axiosApiInstance.post(`${BASE_URL}/api/bookEvent/uploadBookEventBanner`, obj);
        successAlert("Banner uploaded successfully");
        getAllBanners();
        setLoaded(true);
        setMobileBannerPreview('')
        setBannerPreview('');
        document.getElementById('bookEventBanner').value = '';
        document.getElementById('bookEventMobileBanner').value = '';
      } catch (error) {
        console.error("Banner upload failed:", error);
        setLoaded(true);
      }

    }

  };
  const setOpenBookEventModalFunc = (idsArr, title, id) => {
    setOpenBookEventModal(false);
    getAllCategories();

  };


  const handleDelete = async (id, path) => {

    const logo = [path];

    if (logo.length > 0) {
      setLoaded(false);
      try {
        await axiosApiInstance.post(`${BASE_URL}/api/file/deleteBanners`, logo);
        await axiosApiInstance.delete(`${BASE_URL}/api/bookEvent/delete/${id}`);
        getAllCategories();
        // dispatch(getAllOrganizationLogos(""));
        successAlert("Category deleted successfully");
        setLoaded(true);
      } catch (error) {
        failureAlert("Failed to delete ");
      } finally {
        setLoaded(true);
      }
    }
  };

  const deletePlatform = async (row) => {

    const id = row.value;
    await axiosApiInstance.delete(`${BASE_URL}/api/bookEvent/deletePlatformData/${id}`);
    getAllPlatforms();
  }
  const deleteBanners = async (row) => {
    const { id, mobileBannerPath, bannerPath } = row;
    const banners = [mobileBannerPath, bannerPath];

    if (banners.length > 0) {
      setLoaded(false);
      try {
        await axiosApiInstance.post(BASE_URL + "/api/file/deleteBanners", banners);
        await axiosApiInstance.delete(`${BASE_URL}/api/bookEvent/deleteBanner/${id}`);
        await getAllBanners();
        successAlert("Banners deleted successfully");
      } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        failureAlert("Failed to delete banners");
      } finally {
        setLoaded(true);
      }
    }
  };




  const customStyles = {
    headCells: {
      style: {
        "font-family": "firaSans-semibold",
        "font-size": "15px",
        "border-bottom": "1px solid Black",
        "border-top": "1px solid Black"
      }
    },
    cells: {
      style: {
        "font-family": "firaSans-regular",
        "font-size": "14px",
        "text-align": "center"
      }
    },
    header: {
      style: {
        "font-family": "firaSans-semibold",
        color: "#737373"
      }
    }
  };
  const columns = [
    {
      name: "Sr No",
      selector: (row, index) => index + 1,
      center: true
    },
    {
      name: "Category Logo",
      selector: (row) => row.path,
      cell: row => {
        const imageUrl = `${S3_BASE_URL}${row.categoryImage}`;
        return <img src={imageUrl} style={{ width: '100px', height: '100px', padding: '10px' }} />;
      },
      center: true
    },
    {
      name: "Title",
      selector: (row) => row.title
    },
    {
      name: "Created Date",
      selector: (row) => new Date(row.createdAt).toLocaleDateString()
    },
    {
      name: "Actions",
      cell: row => (<>

        <button
          onClick={() => handleDelete(row.id, row.categoryImage)}
          className="btn btn-primary"
          style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
        >
          <img
            src={deleteIcon}
            alt="Delete"
            style={{ width: '20px', height: '20px' }}
          />
        </button>
      </>
      ),
      center: true
    }
  ];
  const bannerColumns = [
    {
      name: "Sr No",
      selector: (row, index) => index + 1,
      center: true
    },
    {
      name: "Desktop Banner",
      selector: (row) => row.path,
      cell: row => {
        const imageUrl = `${S3_BASE_URL}${row.bannerPath}`;
        return <img src={imageUrl} style={{ width: '300px', height: '100px', padding: '10px', borderRadius: '6px' }} />;
      },
      center: true
    },
    {
      name: "Mobile Banner",
      selector: (row) => row.path,
      cell: row => {
        const imageUrl = `${S3_BASE_URL}${row.mobileBannerPath}`;
        return <img src={imageUrl} style={{ width: '300px', height: '100px', padding: '10px', borderRadius: '6px' }} />;
      },
      center: true
    },
    {
      name: "Created Date",
      selector: (row) => new Date(row.createdAt).toLocaleDateString()
    },
    {
      name: "Actions",
      cell: row => (<>

        <button
          onClick={() => deleteBanners(row)}
          className="btn btn-primary"
          style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
        >
          <img
            src={deleteIcon}
            alt="Delete"
            style={{ width: '20px', height: '20px' }}
          />
        </button>
      </>
      ),
      center: true
    }
  ];
  const platformColumns = [
    {
      name: "Sr No",
      selector: (row, index) => index + 1,
      center: true
    },

    {
      name: "Title",
      selector: (row) => row.label
    },
    {
      name: "Actions",
      cell: row => (<>

        <button
          onClick={() => deletePlatform(row)}
          className="btn btn-primary"
          style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
        >
          <img
            src={deleteIcon}
            alt="Delete"
            style={{ width: '20px', height: '20px' }}
          />
        </button>
      </>
      ),
      center: true
    }
  ];
  const handleMultiSelectChangePltforms = (options) => {

    setMultiSelectOptionsplatforms(options);
    setFormData((prevData) => ({
      ...prevData,
      supportedPlatforms: options
    }));
  };
  const handleMultiSelectChange = (options) => {

    setMultiSelectOptions(options);
    setFormData((prevData) => ({
      ...prevData,
      categories: options
    }));
  };
  const [rows, setRows] = useState([{ images: [null], errors: [''] }]);
  const [rowsOnEdit, setRowsOnEdit] = useState([{ images: [null], errors: [''] }]);
  const addRow = () => {
    setRows([...rows, { images: [null], errors: [''] }]);
  };


  const validateImageDimensions = (file, expectedWidth, expectedHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const url = URL.createObjectURL(file);

      img.onload = () => {
        URL.revokeObjectURL(url);
        if (img.width === expectedWidth && img.height === expectedHeight) {
          resolve(true);
        } else {
          reject(`Image dimensions should be ${expectedWidth}x${expectedHeight}`);
        }
      };

      img.onerror = () => {
        URL.revokeObjectURL(url);
        reject('Error loading image');
      };

      img.src = url;
    });
  };

  const validateVideoDimensions = (file, expectedWidth, expectedHeight) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      const url = URL.createObjectURL(file);

      video.onloadedmetadata = () => {
        URL.revokeObjectURL(url);
        if (video.videoWidth === expectedWidth && video.videoHeight === expectedHeight) {
          resolve(true);
        } else {
          reject(`Video dimensions should be ${expectedWidth}x${expectedHeight}`);
        }
      };

      video.onerror = () => {
        URL.revokeObjectURL(url);
        reject('Error loading video');
      };

      video.src = url;
    });
  };

  useEffect(() => {
    const newRowsFaq = faqsData.map(item => ({
      title: item.title,
      description: item.description,
      errors: { title: '', description: '' }
    }));

    setRowsFaq(newRowsFaq);
  }, [faqsData]);

  useEffect(() => {
    const newTestimonialRows = testimonialData.map(item => ({
      id: item.id,
      image: item.testimonialImagePath,
      name: item.name,
      designation: item.designation,
      comment: item.comment,
      errors: { image: '', name: '', designation: '', comment: '' }
    }));

    setTestimonialRows(newTestimonialRows);
  }, [testimonialData]);

  const updateBannerPaths = (bannersArray, rowIndex, colIndex, newPath) => {
    if (rowIndex >= bannersArray.length) {
      for (let i = bannersArray.length; i <= rowIndex; i++) {
        bannersArray.push({
          mobileBannerPath: '',
          desktopBannerPath: ''
        });
      }
    }

    if (colIndex === 0) {
      bannersArray[rowIndex].mobileBannerPath = newPath;
    } else if (colIndex === 1) {
      bannersArray[rowIndex].desktopBannerPath = newPath;
    }
    return bannersArray;
  };


  const removeRow = (rowIndex) => {
    setRows(rows.filter((_, index) => index !== rowIndex));
  };
  const uploadImage = async (file) => {
    const data = new FormData();
    data.append("banner-images", file);

    try {
      const response = await dispatch(uploadFile(data));
      return response.data.data.path;
    } catch (error) {
      console.error('Upload failed:', error);
      return null;
    }
  };
  const uploadVideo = async (file) => {
    const data = new FormData();
    data.append("videobanners", file);

    try {
      const response = await dispatch(uploadFile(data));
      return response.data.data.path;
    } catch (error) {
      console.error('Upload failed:', error);
      return null;
    }
  };


  const [rowsFaq, setRowsFaq] = useState([
    { title: '', description: '', errors: { title: '', description: '' } },
  ]);

  const handleInputChange = (rowIndex, field, value) => {
    const updatedRows = rowsFaq.map((row, i) => {
      if (i === rowIndex) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setRowsFaq(updatedRows);
  };



  const addRowFaq = () => {
    setRowsFaq([
      ...rowsFaq,
      { title: '', description: '', errors: { title: '', description: '' } },
    ]);
  };

  const removeRowFaq = (index) => {
    const updatedRows = rowsFaq.filter((_, i) => i !== index);
    setRowsFaq(updatedRows);
  };

  const [testimonialRows, setTestimonialRows] = useState([
    {
      image: null,
      name: '',
      designation: '',
      comment: '',
      errors: { image: '', name: '', designation: '', comment: '' },
    },
  ]);
  const [gamesRows, setGamesRows] = useState([
    {
      image: null,
      errors: { image: '' },
    },
  ]);
  const [mainGamesRows, setMainGamesRows] = useState([
    {
      image: null,
      errors: { image: '' },
    },
  ]);
  const handleTestimonialFileUpload = (rowIndex, event) => {
    const file = event.target.files[0];

    if (file) {

      const MAX_SIZE_MB = 2;
      const MAX_SIZE = MAX_SIZE_MB * 1024 * 1024;

      const updatedRows = [...testimonialRows];


      updatedRows[rowIndex].errors.image = '';


      if (file.size > MAX_SIZE) {
        updatedRows[rowIndex].errors.image = `File size must be less than ${MAX_SIZE_MB}MB.`;
        setTestimonialRows(updatedRows);
        event.target.value = '';
        return;
      }

      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {

        if (img.width !== 300 || img.height !== 300) {
          updatedRows[rowIndex].errors.image = 'Image must be 300x300 pixels.';
          setTestimonialRows(updatedRows);
          event.target.value = '';
        } else {

          updatedRows[rowIndex].image = file;
          setTestimonialRows(updatedRows);
        }
      };

      img.onerror = () => {
        updatedRows[rowIndex].errors.image = 'Invalid image file.';
        setTestimonialRows(updatedRows);
        event.target.value = '';
      };
    } else {

      event.target.value = '';
    }
  };


  const handleMainFileUploadOnEdit = (index, event, pathtodelete) => {

    const file = event.target.files[0];
    const updatedRows = [...fileOrLinkRows];

    if (file) {
      if (file.size > 20 * 1024 * 1024) {
        updatedRows[index].errors.image = 'File size must be less than 20MB';
        setFileOrLinkRows(updatedRows);
        return;
      }

      const img = new Image();
      img.onload = async () => {
        // if (img.width !== 400 || img.height !== 200) {
        //   updatedRows[index].errors.image = 'Image dimensions must be 400 x 200 pixels';
        // } else {
        const r = await uploadImage(file);
        const obj = { gameId: editGameId, path: r, index: index };
        if (pathtodelete) {
          const pathsArray = [pathtodelete];

          await axiosApiInstance.post(BASE_URL + "/api/file/deleteBanners", pathsArray);
        }

        await axiosApiInstance.post(BASE_URL + "/api/bookEvent/updateGamesImages", obj);
        await getAllGamesImages(editGameId);
        // updatedRows[index].errors.image = '';
        // //  }
        // setFileOrLinkRows([...updatedRows]);
      };
      img.onerror = () => {
        updatedRows[index].errors.image = 'Invalid image file';
        setFileOrLinkRows([...updatedRows]);
      };
      img.src = URL.createObjectURL(file);
    } else {
      updatedRows[index].errors.image = 'File is required';
      setFileOrLinkRows(updatedRows);
    }
  };

  const handleTestimonialInputChange = (rowIndex, field, value) => {
    const updatedRows = testimonialRows.map((row, i) => {
      if (i === rowIndex) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setTestimonialRows(updatedRows);
  };


  const addTestimonialRow = (event) => {
    event.preventDefault();
    setTestimonialRows([
      ...testimonialRows,
      { image: null, name: '', designation: '', comment: '', errors: { image: '', name: '', designation: '', comment: '' } },
    ]);
  };

  const addGamesRow = () => {
    setGamesRows([
      ...gamesRows,
      { image: null, errors: { image: '' } },
    ]);
  };

  const addMainGamesRow = () => {
    setMainGamesRows([
      ...mainGamesRows,
      { image: null, errors: { image: '' } },
    ]);
  };
  const removeTestimonialRow = async (rowIndex, row) => {



    if (row && row.hasOwnProperty('id')) {
      const pathsArray = [
        row.image
      ];
      await axiosApiInstance.post(BASE_URL + "/api/file/deleteBanners", pathsArray);
      await axiosApiInstance.delete(`${BASE_URL}/api/bookEvent/deleteTestimonialOnId/${row.id}`);
      const dataTestimonial = await fetchTestimonialOnGameId(editGameId);
      setTestimonialData(dataTestimonial);
    }
    else {
      const updatedRows = testimonialRows.filter((_, i) => i !== rowIndex);
      setTestimonialRows(updatedRows);

    }
  };


  const processPaths = async (data) => {
    const paths = await Promise.all(
      data.map(async (item) => {
        if (item.type === 'file') {
          const pathReturned = await uploadImage(item.image);

          return { type: 'file', path: pathReturned };
        } else if (item.type === 'link') {
          return { type: 'link', path: item.value };
        }
      })
    );

    return paths;
  };


  const AddNewGame = async (e) => {
    e.preventDefault();
    const faqArrWithNonEmptyValues = rowsFaq
      .filter(item => item.title && item.description)
      .map(item => {

        return {
          title: item.title,
          description: item.description,
          errors: Object.fromEntries(
            Object.entries(item.errors).filter(([key, value]) => value)
          )
        };
      });
    const filteredTestimonials = testimonialRows.filter(item =>
      item.image && item.name && item.designation && item.comment
    );
   

    if (isEditing) {

      if (validateForm()) {
        formData.gameBanner = GameBannerpathOnEdit;
        setLoaded(false);
        const objectsWithoutId = filteredTestimonials.filter(testimonial => !testimonial.hasOwnProperty('id'));
        const testimonialPaths = objectsWithoutId.map(async (row) => {
          const [file1Path] = await Promise.all([
            row.image ? (row.image.type.startsWith('image/') ? uploadImage(row.image) : uploadVideo(row.image)) : null
          ]);
          return { path: file1Path };
        });
        const paths = await Promise.all(testimonialPaths);
        let pathIndex = 0;
        const finalArray = filteredTestimonials.map((item) => {
          const isIdPresent = !!item.id;
          const assignedPath = isIdPresent ? item.image : (paths[pathIndex]?.path || null);
          if (!isIdPresent) {
            pathIndex++;
          }
          return {
            id: isIdPresent ? item.id : null,
            name: item.name,
            designation: item.designation,
            comment: item.comment,
            path: assignedPath
          };
        });
        const obj = {
          formvalues: formData,
          faqs: faqArrWithNonEmptyValues,
          testimonials: finalArray,
          gameid: editGameId,
          categoriesIDS: multiSelectOptions,
          platformIDS: multiSelectOptionsPlatforms,

        }

      
        await axiosApiInstance.put(BASE_URL + "/api/bookEvent/updateGame", obj)
          .then(async (response) => {

            successAlert('Games Updated Successfully')
            getAllGames();
            setLoaded(true);
            setIsNewGame(!isNewGame);
            setIsEditing(false);
            setFormData(initialFormData);
            setId(null);
            setMultiSelectOptions([]);
            setFormErrors([]);


            setFileOrLinkRows([{ type: 'file', value: '', errors: {}, image: null }]);
            getAllGames();
          })
          .catch((error) => {
            failureAlert("Failed to add request");
            setLoaded(true);
          });
      }
    }
    else {
      const newErrors = [];

      fileOrLinkRows.forEach((row, index) => {
        if (row.type === 'link' && !row.value) {
          newErrors[index] = 'Link is required.';
        } else if (row.type === 'link' && !validateYouTubeLink(row.value)) {
          newErrors[index] = 'Please enter a valid YouTube link';
        } else if (row.type === 'file' && !row.image) {
          newErrors[index] = 'File is required';
        }
      });

      if (newErrors.length > 0) {
        setFormErrors(newErrors);
      }


      const file = fileInputRefGameBanner.current.files[0];
      const fileError = await validateGameBannerFile(file);
      setGameBannerFileError(fileError);

      if (validateForm() && !fileError && newErrors.length == 0 && fileOrLinkRows.length == 5) {
        setLoaded(false);

        const paths = await processPaths(fileOrLinkRows);

        formData.maingamespath = paths;

        const testimonaildata = filteredTestimonials.map((row) => ({
          image: row.image,
          name: row.name,
          designation: row.designation,
          comment: row.comment,
        }));
        formData.Faq = faqArrWithNonEmptyValues || [];
        try {

          const GameBannerpath = await uploadImage(file);
          formData.gameBanner = GameBannerpath;

          let finalArray;
          if (testimonaildata.length > 0) {
            const testimonialPaths = testimonaildata.map(async (row) => {
              const [file1Path] = await Promise.all([
                row.image ? (row.image.type.startsWith('image/') ? uploadImage(row.image) : uploadVideo(row.image)) : null
              ]);
              return { path: file1Path };
            });

            const paths = await Promise.all(testimonialPaths);

            finalArray = paths.map((img, index) => ({
              path: img.path,
              name: testimonaildata[index].name,
              designation: testimonaildata[index].designation,
              comment: testimonaildata[index].comment
            }));
          }
          formData.testimonials = finalArray || [];


          await axiosApiInstance.post(BASE_URL + "/api/bookEvent/addGame", formData)
            .then(async (response) => {
              successAlert("Game added successfully");
              setFormData(initialFormData);
              setMultiSelectOptions([]);
              setRowsFaq([
                { title: '', description: '', errors: { title: '', description: '' } },
              ]);
              setTestimonialRows([
                {
                  image: null,
                  name: '',
                  designation: '',
                  comment: '',
                  errors: { image: '', name: '', designation: '', comment: '' },
                },
              ]);
              setFormErrors([]); // Clear errors if the submission is successful
              setGameBannerPreview(null);
              setGameBannerFileError('');
              fileInputRefGameBanner.current.value = '';
              // Reset the form
              setFileOrLinkRows([{ type: 'file', value: '', errors: {}, image: null }]);
              setLoaded(true);
              getAllGames();
              setMultiSelectOptionsplatforms([]);
            })
            .catch((error) => {

              failureAlert("Failed to add request");
              setLoaded(true);
            });


        } catch (error) {
          console.error("Error during file uploads:", error);
        }
      }
      else if (fileOrLinkRows.length !== 5) {
        failureAlert('Please add five banner items')
      }

      const inputElement = document.getElementById('fileInput-0');
      if (inputElement) {
        inputElement.value = '';

      }
    }

  };


  const [isNewGame, setIsNewGame] = useState(true);
  const [editGameId, setId] = useState(null);
  const toggleText = () => {
    setFileOrLinkRows([{ type: 'file', value: '', errors: {}, image: null }]);
    setShowTestimonials(false);
    setShowFaqs(false);
    setIsNewGame(!isNewGame);
    setIsEditing(false);
    setFormData(initialFormData);
    setId(null);
    setMultiSelectOptions([]);
    setMultiSelectOptionsplatforms([]);
    setGamesRows([{ image: null, errors: { image: '' } }]);
    setTestimonialRows([
      {
        image: null,
        name: '',
        designation: '',
        comment: '',
        errors: { image: '', name: '', designation: '', comment: '' },
      },
    ]);
  };
  const deleteGame = async (obj) => {

    const keysToCheck = ['imageFive', 'imageFour', 'imageOne', 'imageThree', 'imageTwo'];

    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
    const imageArray = [];


    keysToCheck.forEach(key => {
      const url = obj[key];
      if (url) {
        const extension = url.split('.').pop().toLowerCase();
        if (imageExtensions.includes(extension)) {
          imageArray.push(url);
        }
      }
    });


    if (imageArray.length > 0) {
      setLoaded(false);
      try {
        await axiosApiInstance.post(BASE_URL + "/api/file/deleteBanners", imageArray);

      } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        failureAlert("Failed to delete banner");
      } finally {
        setLoaded(true);
      }


    }
    if (obj.testimonials.length > 0) {
      const testimonialPaths = obj.testimonials.flatMap(image => [
        image.testimonialImagePath

      ]);
      if (testimonialPaths.length > 0) {
        setLoaded(false);
        try {
          await axiosApiInstance.post(BASE_URL + "/api/file/deleteBanners", testimonialPaths);

        } catch (error) {
          console.error('Error:', error.response ? error.response.data : error.message);
          failureAlert("Failed to delete banner");
        } finally {
          setLoaded(true);
        }
      }

    }
    try {
      setLoaded(false);
      await axiosApiInstance.delete(`${BASE_URL}/api/bookEvent/deleteGame/${obj.id}`);
      successAlert('Game Deleted Successfully');
      getAllGames();
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      failureAlert("Failed to delete game");
    } finally {
      setLoaded(true);
    }

  }
  const [isEditing, setIsEditing] = useState(false);
  async function fetchFaqOnGameId(id) {
    try {
      setLoaded(false);
      const response = await axiosApiInstance.get(`${BASE_URL}/api/bookEvent/getFaqOnGameId/${id}`);

      return response.data.data;
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      failureAlert("Failed to fetch game data");
    } finally {
      setLoaded(true);
    }
  }
  async function fetchTestimonialOnGameId(id) {
    try {
      setLoaded(false);
      const response = await axiosApiInstance.get(`${BASE_URL}/api/bookEvent/getTestimonialOnGameId/${id}`);
      return response.data.data;
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      failureAlert("Failed to fetch game data");
    } finally {
      setLoaded(true);
    }
  }


  async function getGameInfo(id) {
    try {
      setLoaded(false);
      const response = await axiosApiInstance.get(`${BASE_URL}/api/bookEvent/getGameInfo/${id}`);

      return response.data.data;
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      failureAlert("Failed to fetch game000 data");
    } finally {
      setLoaded(true);
    }
  }
  async function getAllPlatforms() {
    try {
      setLoaded(false);
      const response = await axiosApiInstance.get(`${BASE_URL}/api/bookEvent/getAllPlatforms`);
      const result = response.data.data;
      const options = result.map(item => ({
        value: item.id,
        label: item.name,
      }));

      setBookEventPlatforms(options);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      failureAlert("Failed to fetch game000 data");
    } finally {
      setLoaded(true);
    }
  }

  function checkStringType(string) {
    const imagePattern = /\.(jpg|jpeg|png|gif|bmp|tiff)$/i;
    const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)/;

    if (imagePattern.test(string)) {
      return 'image';
    } else if (youtubePattern.test(string)) {
      return 'link';
    } else {
      return 'unknown';
    }
  }
  function isImageFormat(filename) {
    const imageFormats = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.webp'];
    return imageFormats.some(format => filename.endsWith(format));
  }
  const editGame = async (gameobjInfo) => {
    setMultiSelectOptions([]);
    setMultiSelectOptionsplatforms([])
    setId(gameobjInfo?.id)
    const dataFaq = await fetchFaqOnGameId(gameobjInfo?.id);
   
    if (dataFaq.length > 0) {
      setShowFaqs(true);
      setfaqsData(dataFaq);
    }
    const dataTestimonial = await fetchTestimonialOnGameId(gameobjInfo?.id);
    if (dataTestimonial.length > 0) {
      setShowTestimonials(true);
      setTestimonialData(dataTestimonial);
    }
    setIsEditing(true);
    setIsNewGame(!isNewGame);

    const gameInfo = await getGameInfo(gameobjInfo?.id);


    setFileOrLinkRows([])

    const isImage = isImageFormat(gameInfo?.imageOne);
    const isImage2 = isImageFormat(gameInfo?.imageTwo);

    setFileOrLinkRows((prevRows) => [
      ...prevRows,
      {
        type: isImageFormat(gameInfo?.imageOne) ? 'file' : 'link',
        value: isImageFormat(gameInfo?.imageOne) ? '' : gameInfo?.imageOne,
        errors: {},
        image: isImageFormat(gameInfo?.imageOne) ? gameInfo?.imageOne : null
      },
      {
        type: isImageFormat(gameInfo?.imageTwo) ? 'file' : 'link',
        value: isImageFormat(gameInfo?.imageTwo) ? '' : gameInfo?.imageTwo,
        errors: {},
        image: isImageFormat(gameInfo?.imageTwo) ? gameInfo?.imageTwo : null
      },
      {
        type: isImageFormat(gameInfo?.imageThree) ? 'file' : 'link',
        value: isImageFormat(gameInfo?.imageThree) ? '' : gameInfo?.imageThree,
        errors: {},
        image: isImageFormat(gameInfo?.imageThree) ? gameInfo?.imageThree : null
      },
      {
        type: isImageFormat(gameInfo?.imageFour) ? 'file' : 'link',
        value: isImageFormat(gameInfo?.imageFour) ? '' : gameInfo?.imageFour,
        errors: {},
        image: isImageFormat(gameInfo?.imageFour) ? gameInfo?.imageFour : null
      },
      {
        type: isImageFormat(gameInfo?.imageFive) ? 'file' : 'link',
        value: isImageFormat(gameInfo?.imageFive) ? '' : gameInfo?.imageFive,
        errors: {},
        image: isImageFormat(gameInfo?.imageFive) ? gameInfo?.imageFive : null
      },
    ]);

    setGameBannerPathOnEdit(gameInfo?.gameBanner);
    setGameBannerPreview(gameInfo?.gameBanner);
    const seldata = gameInfo?.categories.map(category => ({
      value: category.id,
      label: category.title
    }));
    setMultiSelectOptions(seldata);
    if(gameInfo?.typeOfEvent==='online'){
      const selplatforms = gameInfo?.platforms.map(category => ({
        value: category.id,
        label: category.name
      }));
  
      setMultiSelectOptionsplatforms(selplatforms);
    }
    else{
   
      setMultiSelectOptionsplatforms([]);
    }
 
    setFormData(prevValues => ({
      ...prevValues,
      title: gameInfo?.title,
      description: gameInfo?.description,
      feature: gameInfo?.feature,
      minPlayer: gameInfo?.minPlayers,
      maxPlayer: gameInfo?.maxPlayers,
      duration: gameInfo?.duration,
      eventCost: gameInfo?.costOfEvent?.INR,
      eventCostUSD: gameInfo?.costOfEvent?.USD,
      eventType: gameInfo?.typeOfEvent,
      categories: bookEventCategories,
      difficultylevel: gameInfo?.levelOfDifficulty,
      gameType: gameInfo?.gameType,
      bookingType: gameInfo?.bookingType,
      guaranteeType: gameInfo?.guaranteeType,
      headCount: gameInfo?.headCount,
      howItWorks: gameInfo?.howItWorks,
      agenda: gameInfo?.agenda,
      thingsYouNeed: gameInfo?.thingsYouWillNeed,
      // howToJoin: gameInfo?.howToJoin,
      breakOutRoom: gameInfo?.isBreakOutRoom,
      breakOutRoomSize: gameInfo?.breakOutRoomSize,
      supportedPlatforms: bookEventplatforms
    }));


  };



  const [platformName, setPlatformName] = useState('');
  const [platformNameerror, setplatformNameError] = useState('');

  const handlePlatformNameChange = (event) => {
    setPlatformName(event.target.value);
    setplatformNameError('');
  };

  const submitPlatformData = async (event) => {
    event.preventDefault();
    if (platformName.trim() === '') {
      setplatformNameError('Platform name is required');
    } else {

      let obj = { name: platformName };
      const result = await axiosApiInstance
        .post(BASE_URL + "/api/bookEvent/addPlatform", obj)
        .then((response) => {
          setPlatformName('');
          getAllPlatforms();
        })
        .catch((error) => {
          failureAlert("Failed to add request");
          setLoaded(true);
        });

    }
  };
  const [showTestimonials, setShowTestimonials] = useState(false);
  const [showFaqs, setShowFaqs] = useState(false);

  const handleTestimonialChange = () => {
    setShowTestimonials(prevState => !prevState);
  };

  const handleFaqChange = () => {
    setShowFaqs(prevState => !prevState);
  };

  const [fileOrLinkRows, setFileOrLinkRows] = useState([{ type: 'file', value: '', errors: {}, image: null }]);
  const [formErrors, setFormErrors] = useState([]);

  const addRowToSection = (e) => {
    e.preventDefault();
    setFileOrLinkRows([...fileOrLinkRows, { type: 'file', value: '', errors: {}, image: null }]);
  };

  const handleTypeChange = (index, type) => {
    const updatedRows = [...fileOrLinkRows];
    updatedRows[index].type = type;
    updatedRows[index].value = '';
    updatedRows[index].image = null;
    updatedRows[index].errors = {};
    setFileOrLinkRows(updatedRows);
  };
  const handleTypeChangeOnEdit = (index, type) => {
    const updatedRows = [...fileOrLinkRows];
    updatedRows[index].type = type;
    updatedRows[index].value = '';
    // updatedRows[index].image = null;
    updatedRows[index].errors = {};
    setFileOrLinkRows(updatedRows);
  };
  const handleFileOrLinkChange = (index, value) => {
    const updatedRows = [...fileOrLinkRows];
    updatedRows[index].value = value;
    updatedRows[index].errors.link = '';
    setFileOrLinkRows(updatedRows);
  };
  function validateRow(index) {
    const row = fileOrLinkRows[index];

    // Ensure errors object exists
    row.errors = row.errors || {};

    // Reset previous errors
    row.errors.link = '';
    row.errors.file = '';

    // Validation logic
    if (row.type === 'link') {
      if (!row.value) {
        row.errors.link = 'Link is required.';
      } else if (!validateYouTubeLink(row.value)) {
        row.errors.link = 'Please enter a valid YouTube link';
      }
    } else if (row.type === 'file' && !row.image) {
      row.errors.file = 'File is required';
    }

    // Return the error for this index
    return row.errors;
  }
  const removeFileOrLinkRow = (index) => {
    const updatedRows = fileOrLinkRows.filter((_, i) => i !== index);
    setFileOrLinkRows(updatedRows);
  };
  const saveLink = async (index) => {

    if (fileOrLinkRows[index].value !== '') {

      const obj = { gameId: editGameId, path: fileOrLinkRows[index].value, index: index };
      if (fileOrLinkRows[index].image) {
        const pathsArray = [fileOrLinkRows[index].image];
        await axiosApiInstance.post(BASE_URL + "/api/file/deleteBanners", pathsArray);
      }

      await axiosApiInstance.post(BASE_URL + "/api/bookEvent/updateGamesImages", obj);
      await getAllGamesImages(editGameId);
      successAlert("Image updated successfully");
    }
    else {
      failureAlert("Please add You tube link")
    }

  };
  const handleFileOrLinkFileUpload = (index, event) => {
    const file = event.target.files[0];
    const updatedRows = [...fileOrLinkRows];

    if (file) {
      // Validate file size (limit to 20MB)
      if (file.size > 20 * 1024 * 1024) {
        updatedRows[index].errors.image = 'File size must be less than 20MB';
        updatedRows[index].image = null;
        setFileOrLinkRows(updatedRows);
        return;
      }

      // Check image dimensions
      const img = new Image();
      img.onload = () => {
        // if (img.width !== 400 || img.height !== 200) {
        //   updatedRows[index].errors.image = 'Image dimensions must be 400 x 200 pixels';
        //   updatedRows[index].image = null;
        // } else {
        updatedRows[index].image = file;
        updatedRows[index].errors.image = '';
        // }
        setFileOrLinkRows([...updatedRows]);
      };
      img.onerror = () => {
        updatedRows[index].errors.image = 'Invalid image file';
        updatedRows[index].image = null;
        setFileOrLinkRows([...updatedRows]);
      };
      img.src = URL.createObjectURL(file);
    } else {
      updatedRows[index].errors.image = 'File is required';
      updatedRows[index].image = null;
      setFileOrLinkRows(updatedRows);
    }
  };

  const validateYouTubeLink = (link) => {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
    return youtubeRegex.test(link);
  };




  const handleSearchange = (e) => {
    handleSearch(e.target.value); // Call the debounced function
  };
  return (
    <div className="admin-homepage">
      <ToastContainer position='bottom-center' />
      <LoadingComponent loaded={loaded} />
      {
        openBookEventModal &&
        <AddGamesBookEvent modalid={"demo"} toggle={openBookEventModal} categoryTitle={categoryTitle} idsArr={idsArr} categoryId={categoryId} setOpenBookEventModal={setOpenBookEventModalFunc} />
      }
      <PageLayout
        sidebartitle=""
        active={"Book An Event"}
        category
        sideBarContents={sidebarContentAdmin}
        profile
        signOut={signOut}
        isAdmin={isAdmin}
        {...props}
      >
        <div className="container-payment">
          <div className="payment">
            <h1 className="title">Book event</h1>
          </div>
        </div>

        <ToastContainer position="bottom-center" />

        <div style={{ textAlign: 'center', marginTop: '20px' }}>

          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
            <button
              onClick={() => handleTabClick('Categories')}
              style={{
                padding: '10px 20px',
                backgroundColor: activeTab === 'Categories' ? 'var(--color-theme)' : '#f0f0f0',
                color: activeTab === 'Categories' ? '#fff' : '#000',
                borderRadius: '5px',
                cursor: 'pointer',
                border: 'none'
              }}
            >
              Categories
            </button>
            <button
              onClick={() => handleTabClick('Games')}
              style={{
                padding: '10px 20px',
                backgroundColor: activeTab === 'Games' ? 'var(--color-theme)' : '#f0f0f0',
                color: activeTab === 'Games' ? '#fff' : '#000',
                borderRadius: '5px',
                cursor: 'pointer',
                border: 'none'
              }}
            >
              Games
            </button>
            <button
              onClick={() => handleTabClick('Testimonials')}
              style={{
                padding: '10px 20px',
                backgroundColor: activeTab === 'Testimonials' ? 'var(--color-theme)' : '#f0f0f0',
                color: activeTab === 'Testimonials' ? '#fff' : '#000',
                borderRadius: '5px',
                cursor: 'pointer',
                border: 'none'
              }}
            >
              Banners
            </button>
            <button
              onClick={() => handleTabClick('blockdates')}
              style={{
                padding: '10px 20px',
                backgroundColor: activeTab === 'blockdates' ? 'var(--color-theme)' : '#f0f0f0',
                color: activeTab === 'blockdates' ? '#fff' : '#000',
                borderRadius: '5px',
                cursor: 'pointer',
                border: 'none'
              }}
            >
              Block Event Dates
            </button>
            <button
              onClick={() => handleTabClick('platforms')}
              style={{
                padding: '10px 20px',
                backgroundColor: activeTab === 'platforms' ? 'var(--color-theme)' : '#f0f0f0',
                color: activeTab === 'platforms' ? '#fff' : '#000',
                borderRadius: '5px',
                cursor: 'pointer',
                border: 'none'
              }}
            >
              Platforms Allowed
            </button>

            <button
              onClick={() => handleTabClick('booking-request')}
              style={{
                padding: '10px 20px',
                backgroundColor: activeTab === 'booking-request' ? 'var(--color-theme)' : '#f0f0f0',
                color: activeTab === 'booking-request' ? '#fff' : '#000',
                borderRadius: '5px',
                cursor: 'pointer',
                border: 'none'
              }}
            >
              Booking-request
            </button>

            <button
              onClick={() => handleTabClick('booking-questions')}
              style={{
                padding: '10px 20px',
                backgroundColor: activeTab === 'booking-questions' ? 'var(--color-theme)' : '#f0f0f0',
                color: activeTab === 'booking-questions' ? '#fff' : '#000',
                borderRadius: '5px',
                cursor: 'pointer',
                border: 'none'
              }}
            >
              Booking-questions
            </button>
          </div>


          <div style={{ marginTop: '30px' }}>
            {activeTab === 'Categories' && (
              <div className="flex justify-between gap-5">
                <div className="w-full">
                  <DataTable
                    title="Book Event Categories"
                    columns={columns}
                    data={categories}
                    className="rdt_TableHead"
                    responsive
                    striped
                    highlightOnHover
                    pagination
                    customStyles={customStyles}
                  />

                </div>

                <div className="w-1/2">
                  <form className="book-event-category-form" onSubmit={handleSubmitForm}>
                    <h3 className="mb-4 font-bold text-lg">Add Category</h3>
                    <div className="mb-4 form-group">
                      <label htmlFor="categoryName" className="block mb-2 font-bold">Category Name</label>
                      <input
                        type="text"
                        id="categoryName"
                        name="categoryName"
                        value={categoryName}
                        onChange={handlecategoryNameChange}
                        placeholder="Enter "
                        className="border-gray-300 p-2 border rounded w-full"
                      />
                      {categoryNameError && <div className="mt-2 text-red-500 errormessage">{categoryNameError}</div>}
                    </div>

                    <div className="mb-4 form-group">
                      <label htmlFor="logo" className="block mb-2 font-bold">Category Image</label>
                      <label htmlFor="logo" className="text-gray-500">Size: 150 x 150 px</label>
                      <input
                        type="file"
                        id="categoryImage"
                        name="logo"
                        accept="image/*"
                        ref={fileInputRefCategoryImage}
                        onChange={handleCategoryFileChange}
                        className="mt-2 w-full"
                      />
                      {fileError && <div className="mt-2 text-red-500 errormessage">{fileError}</div>}
                    </div>

                    {preview && (
                      <div className="mb-4 preview-container">
                        <img src={preview} alt="Logo Preview" className="rounded w-32 h-32 logo-preview object-cover" />
                      </div>
                    )}

                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>

            )}
            {activeTab === 'Games' && (
              <div className="bg-white shadow-lg p-4 border">

                <button
                  onClick={toggleText}
                  className="bg-blue-500 mt-2 px-4 py-2 rounded text-white"
                >
                  {isNewGame ? "Add New Game" : "All Games"}
                </button>
                {isNewGame ? (
                  <div>
                    <h3 className="mt-4 mb-4 pt-4 font-bold text-left text-lg">All Games ({AllGamesOnfilter.length})</h3>
                    <div className="flex justify-start">
                      <input
                        type="text"
                        placeholder="Search Games"
                        onChange={handleSearchChange}
                        className="mb-4 p-2 border rounded"
                      />
                    </div>

                    <div className="gap-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-5 mb-12">
                      {AllGamesOnfilter && AllGamesOnfilter.length > 0 && AllGamesOnfilter.map((card) => (
                        <div
                          key={card.id}
                          className="relative bg-white mb-8 rounded-md card1 group"
                          style={{ width: '100%', maxWidth: '400px', height: '200px' }} // Card dimensions with max-width
                        >
                          <img
                            src={S3_BASE_URL + card?.gameBanner}
                            alt={card.title}
                            className="imgFit"
                          />
                          <h3 className="mt-2 mb-4 font-semibold text-left">{card.title}</h3>

                          <div className="top-2 right-2 absolute flex space-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <button
                              className="bg-orange-500 px-3 py-1 rounded text-sm text-white"
                              onClick={() => editGame(card)}
                            >
                              Edit
                            </button>
                            <button
                              className="bg-red-500 px-3 py-1 rounded text-sm text-white"
                              onClick={() => deleteGame(card)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      ))}
                      {AllGamesOnfilter && AllGamesOnfilter.length === 0 && <p>No Games Found</p>}
                    </div>


                  </div>

                ) : (
                  <div>
                    <h3 className="mb-4 font-bold text-left text-lg">{isEditing ? 'Update' : 'Add'} Game </h3>
                    <form class="gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4" >

                      <div>
                        <label className="block font-medium text-gray-700 text-left text-sm">Title</label>
                        <input type="text"
                          id="title"
                          name="title"
                          value={formData.title}
                          onChange={handleTextChange}
                          placeholder="Enter title"
                          className="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm" />
                        {errors.title && <p className="text-left text-red-500 text-sm">{errors.title}</p>}
                      </div>
                      <div>
                        <label for="firstName" class="block font-medium text-gray-700 text-left text-sm">Description</label>
                        <input type="text"
                          id="description"
                          name="description"
                          value={formData.description}
                          placeholder="Enter description"
                          onChange={handleTextChange} class="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm" />
                        {errors.description && <p className="text-left text-red-500 text-sm">{errors.description}</p>}
                      </div>

                      <div>
                        <label for="firstName" class="block font-medium text-gray-700 text-left text-sm">Feature</label>
                        <input type="text" id="feature"
                          name="feature"
                          value={formData.feature}
                          placeholder="Enter feature"
                          onChange={handleTextChange} class="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm" />
                        {errors.feature && <p className="text-left text-red-500 text-sm">{errors.feature}</p>}
                      </div>
                      <div>
                        <label for="firstName" class="block font-medium text-gray-700 text-left text-sm">Minimum People that can play</label>
                        <input type="number"
                          id="minPlayer"
                          name="minPlayer"
                          value={formData.minPlayer}
                          onChange={handleTextChange}
                          min="1"

                          placeholder="Enter "
                          class="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm" />
                        {errors.minPlayer && <p className="text-left text-red-500 text-sm">{errors.minPlayer}</p>}
                      </div>
                      <div>
                        <label for="firstName" class="block font-medium text-gray-700 text-left text-sm">Maximum People that can play</label>
                        <input type="number"
                          id="maxPlayer"
                          name="maxPlayer"
                          value={formData.maxPlayer}
                          onChange={handleTextChange}
                          min="1"
                          placeholder="Enter "
                          class="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm" />
                        {errors.maxPlayer && <p className="text-left text-red-500 text-sm">{errors.maxPlayer}</p>}
                      </div>

                      <div>
                        <label for="lastName" class="block font-medium text-gray-700 text-left text-sm">Duration (in minutes)</label>
                        <input type="number"
                          id="duration"
                          name="duration"
                          value={formData.duration}
                          placeholder="Enter duration "
                          onChange={handleTextChange} class="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm" />
                        {errors.duration && <p className="text-left text-red-500 text-sm">{errors.duration}</p>}
                      </div>


                      <div>
                        <label for="email" class="block font-medium text-gray-700 text-left text-sm">Cost of the event (INR)</label>
                        <input type="number"
                          id="eventCost"
                          name="eventCost"
                          value={formData.eventCost}
                          placeholder="Enter cost of event"
                          step="0.01"
                          onChange={handleTextChange} class="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm" />
                        {errors.eventCost && <p className="text-left text-red-500 text-sm">{errors.eventCost}</p>}
                      </div>


                      <div>
                        <label for="email" class="block font-medium text-gray-700 text-left text-sm">Cost of the event (USD)</label>
                        <input type="number"
                          id="eventCostUSD"
                          name="eventCostUSD"
                          value={formData.eventCostUSD}
                          placeholder="Enter cost of event"
                          step="0.01"
                          onChange={handleTextChange} class="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm" />
                        {errors.eventCostUSD && <p className="text-left text-red-500 text-sm">{errors.eventCostUSD}</p>}
                      </div>
                      <div>
                        <label for="phone" class="block font-medium text-gray-700 text-left text-sm">Type of event</label>
                        <select
                          id="eventType"
                          name="eventType"
                          value={formData.eventType}
                          onChange={handleTextChange}

                          className="border-gray-300 p-2 border rounded-md w-full h-[40px]"
                        >
                          <option value="">Select Game Type</option>
                          <option value="online">Virtual</option>
                          <option value="offline">Onsite</option>
                        </select>
                        {errors.eventType && <p className="block text-left text-red-500 text-sm">{errors.eventType}</p>}
                      </div>



                      <div>
                        <label for="email" class="block font-medium text-gray-700 text-left text-sm">Difficulty Level</label>
                        <select
                          id="difficultylevel"
                          name="difficultylevel"
                          value={formData.difficultylevel}
                          onChange={handleTextChange}
                          className="border-gray-300 p-2 border rounded-md w-full h-[40px]"
                        >
                          <option value="">Select Difficulty Level</option>
                          <option value="low">Low</option>
                          <option value="medium">Medium</option>
                          <option value="high">High</option>
                        </select>
                        {errors.difficultylevel && <p className="text-left text-red-500 text-sm">{errors.difficultylevel}</p>}
                      </div>


                      <div>
                        <label for="phone" class="block font-medium text-gray-700 text-left text-sm">Game Type</label>
                        <select
                          id="gameType"
                          name="gameType"
                          value={formData.gameType}
                          onChange={handleTextChange}
                          className="border-gray-300 p-2 border rounded-md w-full h-[40px]"
                        >
                          <option value="">Select Game Type</option>
                          <option value="individual">Individual</option>
                          <option value="team">Team</option>

                        </select>
                        {errors.gameType && <p className="text-left text-red-500 text-sm">{errors.gameType}</p>}
                      </div>

                      <div>
                        <label for="address" class="block font-medium text-gray-700 text-left text-sm">Booking Type</label>
                        <select
                          id="bookingType"
                          name="bookingType"
                          value={formData.bookingType}
                          onChange={handleTextChange}
                          className="border-gray-300 p-2 border rounded-md w-full h-[40px]"
                        >
                          <option value="">Select Booking Type</option>
                          <option value="instant">Instant</option>
                          <option value="requestdate">Request Date</option>
                        </select>
                        {errors.bookingType && <p className="text-left text-red-500 text-sm">{errors.bookingType}</p>}
                      </div>
                      <div>
                        <label for="address" class="block font-medium text-gray-700 text-left text-sm">Guarantee Type</label>
                        <select
                          id="guaranteeType"
                          name="guaranteeType"
                          value={formData.guaranteeType}
                          onChange={handleTextChange}
                          className="border-gray-300 p-2 border rounded-md w-full h-[40px]"
                        >
                          <option value="">Select Guarantee Type</option>
                          <option value="extramile">Exramile</option>
                          <option value="host">Host</option>
                        </select>
                        {errors.guaranteeType && <p className="text-left text-red-500 text-sm">{errors.guaranteeType}</p>}
                      </div>

                      <div>
                        <label for="address" class="block font-medium text-gray-700 text-left text-sm">Head Count</label>
                        <input type="number"
                          id="headCount"
                          name="headCount"
                          value={formData.headCount}
                          onChange={handleTextChange}
                          placeholder="Enter"
                          min="1" class="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm" />
                        {errors.headCount && <p className="text-left text-red-500 text-sm">{errors.headCount}</p>}
                      </div>


                      <div>
                        <label for="address" class="block font-medium text-gray-700 text-left text-sm">How It Works</label>
                        <input type="text"
                          id="howItWorks"
                          name="howItWorks"
                          value={formData.howItWorks}
                          onChange={handleTextChange}
                          placeholder="Enter"
                          class="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm" />
                        {errors.howItWorks && <p className="text-left text-red-500 text-sm">{errors.howItWorks}</p>}
                      </div>

                      <div>
                        <label for="address" class="block font-medium text-gray-700 text-left text-sm">Agenda Of The Show</label>
                        <input type="text"
                          id="agenda"
                          name="agenda"
                          value={formData.agenda}
                          onChange={handleTextChange}
                          placeholder="Enter"
                          class="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm" />
                        {errors.agenda && <p className="text-left text-red-500 text-sm">{errors.agenda}</p>}
                      </div>
                      <div>
                        <label for="address" class="block font-medium text-gray-700 text-left text-sm">Things You Will Need</label>
                        <input type="text"
                          id="thingsYouNeed"
                          name="thingsYouNeed"
                          value={formData.thingsYouNeed}
                          onChange={handleTextChange}
                          placeholder="Enter"
                          class="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm" />
                        {errors.thingsYouNeed && <p className="text-left text-red-500 text-sm">{errors.thingsYouNeed}</p>}
                      </div>

                      <div>
                        <label for="address" class="block font-medium text-gray-700 text-left text-sm">Event use breakout room</label>
                        <select
                          id="breakOutRoom"
                          name="breakOutRoom"
                          value={formData.breakOutRoom}
                          onChange={handleTextChange}
                          className="border-gray-300 p-2 border rounded-md w-full h-[40px]"
                        >
                          <option value="">Select </option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                        {errors.breakOutRoom && <p className="text-left text-red-500 text-sm">{errors.breakOutRoom}</p>}
                      </div>

                      {formData.breakOutRoom === 'yes' && (
                        <div>
                          <label for="firstName" class="block font-medium text-gray-700 text-left text-sm">Break out room size</label>
                          <input type="number"
                            id="breakOutRoomSize"
                            name="breakOutRoomSize"
                            value={formData.breakOutRoomSize}
                            onChange={handleTextChange}
                            min="1"
                            placeholder="Enter "
                            class="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm" />
                          {errors.breakOutRoomSize && <p className="text-left text-red-500 text-sm">{errors.breakOutRoomSize}</p>}
                        </div>
                      )}

                      <div>
                        <label for="address" class="block font-medium text-gray-700 text-left text-sm">Category</label>

                        <Select
                          value={multiSelectOptions}
                          onChange={handleMultiSelectChange}
                          options={bookEventCategories}
                          isMulti
                          placeholder="Select Category"
                        />
                        {errors.categories && <p className="text-left text-red-500 text-sm">{errors.categories}</p>}
                      </div>
                      {formData.eventType === 'online' &&
                        <div>
                          <label for="address" class="block font-medium text-gray-700 text-left text-sm">How To Join</label>

                          <Select
                            value={multiSelectOptionsPlatforms}
                            onChange={handleMultiSelectChangePltforms}
                            options={bookEventplatforms}
                            isMulti
                            placeholder="Select Category"
                          />
                          {errors.supportedPlatforms && <p className="text-left text-red-500 text-sm">{errors.supportedPlatforms}</p>}
                        </div>
                      }
                      <div className="col-span-full mt-4">
                        <div className="container-feature">
                          <div className="flex items-start gap-4">


                            {isEditing ? (
                              <>
                                <div className="mb-4 w-full md:w-1/2 form-group">
                                  <div className="text-left">
                                    <label htmlFor="logo" className="block mb-4 font-bold text-left text-lg">Event Thumbnail</label>
                                    <label htmlFor="logo" className="text-gray-500">Size: 400 x 200</label>
                                  </div>

                                  <input
                                    type="file"
                                    id="gameBanner"
                                    name="gameBanner"
                                    accept="image/*"
                                    ref={fileInputRefGameBanner}
                                    onChange={handleGameBannerChangeOnEdit}
                                    className="mt-2 w-full"
                                  />
                                  {gameBannerFileError && (
                                    <div className="mt-2 text-red-500 errormessage">{gameBannerFileError}</div>
                                  )}


                                </div>

                                {gameBannerPreview && (
                                  <div className="mb-4 preview-container w-full md:w-1/3">
                                    <img
                                      src={S3_BASE_URL + gameBannerPreview}
                                      alt="Logo Preview"
                                      className="rounded w-32 h-32 logo-preview object-cover"
                                    />
                                  </div>
                                )}

                              </>
                            ) : (
                              <>
                                <div className="mb-4 w-full md:w-1/2 form-group">
                                  <div className="text-left">
                                    <h3 className="mb-4 font-bold text-left">Event Thumbnail</h3>

                                    <label htmlFor="logo" className="text-gray-500">Size: 400 x 200 px</label>
                                  </div>

                                  <input
                                    type="file"
                                    id="gameBanner"
                                    name="gameBanner"
                                    accept="image/*"
                                    ref={fileInputRefGameBanner}
                                    onChange={handleGameBannerChange}
                                    className="mt-2 w-full"
                                  />
                                  {gameBannerFileError && (
                                    <div className="mt-2 text-red-500 errormessage">{gameBannerFileError}</div>
                                  )}


                                </div>

                                {gameBannerPreview && (
                                  <div className="mb-4 preview-container w-full md:w-1/3">
                                    <img
                                      src={gameBannerPreview}
                                      alt="Logo Preview"
                                      className="rounded w-32 h-32 logo-preview object-cover"
                                    />
                                  </div>
                                )}

                              </>
                            )}
                          </div>
                        </div>
                      </div>


                      <div className="col-span-full mt-4">
                        <div className="container-feature">
                          <h3 className="mb-4 font-bold text-left">Main Images/Videos</h3>
                          <p className="mb-4 text-left">Size 850 x 950</p>
                          <div className="formRow">

                            <div className="form-column">
                              Upload files/link
                            </div>


                            <div className="form-column remove-column">
                              <button onClick={addRowToSection} className="add-row-button">Add</button>
                            </div>


                          </div>

                          {isEditing ? (

                            fileOrLinkRows && fileOrLinkRows.map((row, rowIndex) => (
                              <div className="formRow" key={rowIndex}>
                                <div className="form-row" style={{ display: 'flex', alignItems: 'center' }}>
                                  <select
                                    className="block border-gray-300 bg-gray-50 mr-12 p-2.5 border focus:border-blue-500 rounded-lg focus:ring-blue-500 w-full text-gray-900 text-sm"
                                    value={row.type}
                                    onChange={(e) => handleTypeChangeOnEdit(rowIndex, e.target.value)}
                                  >
                                    <option value="link">Link</option>
                                    <option value="file">File</option>
                                  </select>
                                  {row && row.type == 'file' ? (
                                    <>

                                      <input
                                        type="file"
                                        accept="image/x-png,image/jpg,image/jpeg,image/gif"
                                        onChange={(e) => handleMainFileUploadOnEdit(rowIndex, e, row.image)}
                                        style={{ marginRight: '10px', flex: '1' }} // Space between input and image
                                      />
                                      <img
                                        src={S3_BASE_URL + row.image}
                                        alt="media preview"
                                        style={{
                                          height: '150px',
                                          width: '150px',
                                          objectFit: 'cover',
                                          marginLeft: '10px', // Space if needed
                                          flexShrink: 0 // Prevent shrinking
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>


                                        <input
                                          type="text"
                                          placeholder="Enter link"
                                          value={row.value}
                                          onChange={(e) => handleFileOrLinkChange(rowIndex, e.target.value)}
                                          className="block border-gray-300 bg-gray-50 p-2.5 border focus:border-blue-500 rounded-lg focus:ring-blue-500 w-full text-gray-900 text-sm"
                                        />
                                        {/* {row && (
                                          <p className="mt-1 text-red-500">{row[rowIndex].errors.link}</p>
                                        )} */}
                                      </div>
                                    </>
                                  )}

                                </div>
                                {row.type === 'link' &&
                                  <div className="form-column remove-column">
                                    <button
                                      className="add-row-button"
                                      onClick={(event) => { event.preventDefault(); saveLink(rowIndex); }}
                                    >
                                      Update
                                    </button>
                                  </div>}

                              </div>
                            ))

                          ) : (
                            fileOrLinkRows.map((row, rowIndex) => (
                              <div className="formRow" key={rowIndex}>
                                <div className="form-column">
                                  <select
                                    className="block border-gray-300 bg-gray-50 p-2.5 border focus:border-blue-500 rounded-lg focus:ring-blue-500 w-full text-gray-900 text-sm"
                                    value={row.type}
                                    onChange={(e) => handleTypeChange(rowIndex, e.target.value)}
                                  >
                                    <option value="link">Link</option>
                                    <option value="file">File</option>
                                  </select>
                                </div>
                                <div className="form-column">
                                  {row.type === 'link' ? (
                                    <input
                                      className="block border-gray-300 bg-gray-50 p-2.5 border focus:border-blue-500 rounded-lg focus:ring-blue-500 w-full text-gray-900 text-sm"
                                      type="text"
                                      id={`fileInput-${rowIndex}`}
                                      placeholder="Enter link"
                                      value={row.value}
                                      onChange={(e) => handleFileOrLinkChange(rowIndex, e.target.value)}
                                    />
                                  ) : (
                                    <input
                                      type="file"
                                      id={`fileInput-${rowIndex}`}
                                      accept="image/x-png,image/jpg,image/jpeg,image/gif"
                                      onChange={(e) => handleFileOrLinkFileUpload(rowIndex, e)}
                                    />
                                  )}
                                  {formErrors[rowIndex] && <div className="text-red-500">{formErrors[rowIndex]}</div>}
                                </div>
                                <div className="form-column">
                                  {row.image && row.image.type.startsWith('image/') ? (
                                    <img
                                      src={URL.createObjectURL(row.image)}
                                      alt="preview"
                                      style={{
                                        height: '150px',
                                        width: '150px',
                                        objectFit: 'cover',
                                      }}
                                    />
                                  ) : null}

                                </div>
                                <div className="form-column remove-column">
                                  <button
                                    className="remove-button"
                                    onClick={(event) => { event.preventDefault(); removeFileOrLinkRow(rowIndex); }}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))
                          )}

                        </div>



                        <div className="container-feature">
                          <h3 className="mb-4 font-bold text-left">Add Faq's
                            <input
                              type="checkbox"
                              checked={showFaqs}
                              className="ml-3"
                              onChange={handleFaqChange}
                            /></h3>
                          {showFaqs && (<div>
                            <div className="formRow">
                              <div className="form-column">
                                <h3 className="field-title">FAQ Title</h3>
                              </div>
                              <div className="form-column">
                                <h3 className="field-title">FAQ Description</h3>
                              </div>
                              <div className="form-column remove-column">
                                <button type="button" onClick={(event) => { event.preventDefault(); addRowFaq(); }}
                                  className="add-row-button">
                                  Add Row
                                </button>
                              </div>
                            </div>

                            {rowsFaq.map((row, rowIndex) => (
                              <div key={rowIndex} className="formRow">
                                <div className="form-column">
                                  <input
                                    type="text"
                                    placeholder="Enter FAQ Title"
                                    value={row.title}
                                    className="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm"
                                    onChange={(e) => handleInputChange(rowIndex, 'title', e.target.value)}
                                  />
                                  {row.errors.title && <div className="errMsg">{row.errors.title}</div>}
                                </div>
                                <div className="form-column">
                                  <textarea
                                    placeholder="Enter FAQ Description"
                                    value={row.description}
                                    className="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm"
                                    onChange={(e) => handleInputChange(rowIndex, 'description', e.target.value)}
                                  />
                                  {row.errors.description && <div className="errMsg">{row.errors.description}</div>}
                                </div>
                                <div className="form-column remove-column">
                                  <button type="button" onClick={(event) => { event.preventDefault(); removeRowFaq(rowIndex); }} className="remove-button">
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}

                          </div>)}
                        </div>

                        <div className="container-feature">

                          <h3 className="mb-4 font-bold text-left">Add Testimonial
                            <input
                              type="checkbox"
                              checked={showTestimonials}
                              className="ml-3"
                              onChange={handleTestimonialChange}
                            />
                          </h3>
                          {showTestimonials && (<div>
                            <p className="mb-4 text-left">Size 300 x 300</p>
                            <div className="formRow">
                              <div className="form-column">
                                <h3 className="file-title">Testimonial Image</h3>
                              </div>
                              <div className="form-column">

                              </div>
                              <div className="form-column">
                                <h3 className="file-title">Name</h3>
                              </div>
                              <div className="form-column">
                                <h3 className="file-title">Designation</h3>
                              </div>
                              <div className="form-column">
                                <h3 className="file-title">Comment</h3>
                              </div>
                              <div className="form-column remove-column">
                                <button onClick={addTestimonialRow} className="add-row-button">Add Testimonial</button>
                              </div>
                            </div>

                            {isEditing ? (
                              <>
                                <>
                                  {testimonialRows.map((row, rowIndex) => (
                                    <div key={rowIndex} className="formRow">
                                      <div className="form-column">
                                        <input
                                          type="file"
                                          accept="image/x-png,image/jpg,image/jpeg,image/gif"
                                          onChange={(e) => handleTestimonialFileUpload(rowIndex, e)}
                                        />

                                        {row.errors.image && <div className="errMsg">{row.errors.image}</div>}
                                      </div>
                                      <div className="form-column">
                                        {row.image ? (
                                          <img
                                            src={typeof row.image === 'string' ? S3_BASE_URL + row.image : URL.createObjectURL(row.image)}
                                            alt="preview"
                                            style={{
                                              height: '50px',
                                              width: '50px',
                                              borderRadius: '50%',
                                              objectFit: 'cover',
                                            }}
                                          />
                                        ) : (
                                          <div>No image uploaded</div>
                                        )}
                                      </div>
                                      <div className="form-column">
                                        <input
                                          type="text"
                                          placeholder="Enter Name"
                                          value={row.name}
                                          className="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm"
                                          onChange={(e) => handleTestimonialInputChange(rowIndex, 'name', e.target.value)}
                                        />
                                        {row.errors.name && <div className="errMsg">{row.errors.name}</div>}
                                      </div>
                                      <div className="form-column">
                                        <input
                                          type="text"
                                          placeholder="Enter Designation"
                                          value={row.designation}
                                          className="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm"
                                          onChange={(e) => handleTestimonialInputChange(rowIndex, 'designation', e.target.value)}
                                        />
                                        {row.errors.designation && <div className="errMsg">{row.errors.designation}</div>}
                                      </div>
                                      <div className="form-column">
                                        <textarea
                                          placeholder="Enter Comment"
                                          value={row.comment}
                                          className="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm"
                                          onChange={(e) => handleTestimonialInputChange(rowIndex, 'comment', e.target.value)}
                                        />
                                        {row.errors.comment && <div className="errMsg">{row.errors.comment}</div>}
                                      </div>
                                      <div className="form-column remove-column">
                                        <button
                                          onClick={(event) => { event.preventDefault(); removeTestimonialRow(rowIndex, row); }}

                                          className="remove-button"
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              </>
                            ) : (
                              <>

                                {testimonialRows.map((row, rowIndex) => (
                                  <div key={rowIndex} className="formRow">
                                    <div className="form-column">
                                      <input
                                        type="file"
                                        accept="image/x-png,image/jpg,image/jpeg,image/gif"
                                        onChange={(e) => handleTestimonialFileUpload(rowIndex, e)}
                                      />

                                      {row.errors.image && <div className="errMsg">{row.errors.image}</div>}
                                    </div>
                                    <div className="form-column">
                                      {row.image && (
                                        <img
                                          src={URL.createObjectURL(row.image)}
                                          alt="preview"
                                          className="preview-image"
                                          style={{
                                            height: '50px',
                                            width: '50px',
                                            borderRadius: '50%',
                                            objectFit: 'cover',
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div className="form-column">
                                      <input
                                        type="text"
                                        placeholder="Enter Name"
                                        value={row.name}
                                        className="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm"
                                        onChange={(e) => handleTestimonialInputChange(rowIndex, 'name', e.target.value)}
                                      />
                                      {row.errors.name && <div className="errMsg">{row.errors.name}</div>}
                                    </div>
                                    <div className="form-column">
                                      <input
                                        type="text"
                                        placeholder="Enter Designation"
                                        value={row.designation}
                                        className="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm"
                                        onChange={(e) => handleTestimonialInputChange(rowIndex, 'designation', e.target.value)}
                                      />
                                      {row.errors.designation && <div className="errMsg">{row.errors.designation}</div>}
                                    </div>
                                    <div className="form-column">
                                      <textarea
                                        placeholder="Enter Comment"
                                        value={row.comment}
                                        className="block border-gray-300 shadow-sm mt-1 p-2 border focus:border-blue-500 rounded-md focus:ring-blue-500 w-full sm:text-sm"
                                        onChange={(e) => handleTestimonialInputChange(rowIndex, 'comment', e.target.value)}
                                      />
                                      {row.errors.comment && <div className="errMsg">{row.errors.comment}</div>}
                                    </div>
                                    <div className="form-column remove-column">
                                      <button
                                        onClick={(event) => { event.preventDefault(); removeTestimonialRow(rowIndex); }}

                                        className="remove-button"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </>

                            )}
                          </div>)}
                        </div>
                        <button onClick={AddNewGame} className="mt-12 btn btn-primary">Submit</button>
                      </div>

                    </form></div>
                )}



              </div>




            )}
            {activeTab === 'Testimonials' && (
              <div>



                <form onSubmit={handleBannerSubmit} className="bg-white mx-auto mb-12 p-6 rounded-lg w-full">
                  <div className="flex flex-wrap">

                    <div className="mb-4 pr-2 w-full md:w-1/2">
                      <h3 className="mb-4 font-bold text-left text-lg">Upload Desktop Banner</h3>
                      <p className="mb-4 text-left">Size 1920 x 600</p>
                      <input
                        type="file"
                        id="bookEventBanner"
                        accept="image/jpeg, image/png, image/gif"
                        ref={InputRefDesktopBanner}
                        onChange={handleBannerChange}
                        className="block file:border-0 file:bg-blue-50 hover:file:bg-blue-100 file:mr-4 file:px-4 file:py-2 file:rounded-full w-full file:font-semibold text-gray-500 text-sm file:text-blue-700 file:text-sm"
                      />
                      {!desktopBannerPreview && bannererror && <p className="mb-4 text-left text-red-500 text-sm">{bannererror}</p>}
                      {desktopBannerPreview && (
                        <div className="mt-4">
                          <h3 className="mb-2 font-medium text-gray-700 text-sm">Desktop Banner Preview</h3>
                          <img src={desktopBannerPreview} alt="Selected" className="shadow-sm rounded-md w-full h-48 object-cover" />
                        </div>
                      )}
                    </div>


                    <div className="mb-4 pl-2 w-full md:w-1/2">
                      <h3 className="mb-4 font-bold text-left text-lg">Upload Mobile Banner</h3>
                      <p className="mb-4 text-left">Size 360 x 477</p>
                      <input
                        type="file"
                        id="bookEventMobileBanner"
                        accept="image/jpeg, image/png, image/gif"
                        ref={InputRefMobileBanner}
                        onChange={handleMobileBannerChange}
                        className="block file:border-0 file:bg-blue-50 hover:file:bg-blue-100 file:mr-4 file:px-4 file:py-2 file:rounded-full w-full file:font-semibold text-gray-500 text-sm file:text-blue-700 file:text-sm"
                      />
                      {!mobileBannerPreview && mobileBannererror && <p className="mb-4 text-left text-red-500 text-sm">{mobileBannererror}</p>}
                      {mobileBannerPreview && (
                        <div className="mt-4">
                          <h3 className="mb-2 font-medium text-gray-700 text-sm">Mobile Banner Preview</h3>
                          <img src={mobileBannerPreview} alt="Selected" className="shadow-sm rounded-md w-full h-48 object-cover" />
                        </div>
                      )}
                    </div>
                  </div>


                  <button type="submit" className="bg-orange-600 hover:bg-blue-700 px-4 py-2 rounded-lg text-white transition-all">
                    Submit
                  </button>
                </form>
                <br />
                <DataTable
                  title="All Banners"
                  columns={bannerColumns}
                  data={banners}
                  className="rdt_TableHead"
                  responsive
                  striped
                  highlightOnHover
                  pagination
                  customStyles={customStyles}
                />
              </div>

            )}
            {activeTab === 'blockdates' && (
              <div>
                <div style={{ height: 'auto', marginBottom: '20px', backgroundColor: 'white', padding: '10px', borderRadius: '8px' }}>
                  <h3 className="mb-4 font-bold text-left text-lg">Disable Dates</h3>
                  <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    selectable
                    onSelectSlot={handleSelectSlot}
                    onSelectEvent={handleSelectEvent}
                    eventPropGetter={eventPropGetter}
                    style={{ height: 500 }}
                    defaultView="month"
                    views={['month', 'agenda']}

                  />
                  <button
                    onClick={handleGetAllDates}
                    className="mt-12 btn btn-primary"
                  >
                    Block Selected Dates
                  </button>
                </div>
              </div>

            )}
            {activeTab === 'platforms' && (
              <div>



                <div className="bg-white shadow-md mx-auto p-4 rounded max-w-md">
                  <form onSubmit={submitPlatformData} className="flex flex-col space-y-4">
                    <input
                      type="text"
                      value={platformName}
                      onChange={handlePlatformNameChange}
                      placeholder="Enter platform name"
                      className="border-gray-300 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    />
                    {platformNameerror && (
                      <p className="text-red-500">{platformNameerror}</p>
                    )}
                    <button
                      type="submit"
                      className="bg-blue-500 hover:bg-blue-600 py-2 rounded text-white transition duration-200"
                    >
                      Add
                    </button>
                  </form>

                </div>
                <br />
                <DataTable
                  title="Platforms Allowed"
                  columns={platformColumns}
                  data={bookEventplatforms}
                  className="rdt_TableHead"
                  responsive
                  striped
                  highlightOnHover
                  pagination
                  customStyles={customStyles}
                />
              </div>

            )}
            {
              activeTab === 'booking-request' && (
                <BookEventReqs />
              )
            }
            {
              activeTab === 'booking-questions' && (
                <EventsQuestions />
              )
            }
          </div>
        </div>

      </PageLayout>
    </div>
  );
};
export default BookEventAdmin;