import React, { useEffect, useRef, useState } from 'react';
import Playcardlarge from '../../components/playCard/playcardlarge';
// image
import search from '../../assets/images/search.svg';
import arrowleft from '../../assets/images/paginationarrow.svg';
import { useDispatch, useSelector } from 'react-redux';
import LoginModal from '../../components/modal/loginmodal';
import LikeShareModal from '../../components/modal/likesharemodal';
import { getAllGames } from '../../redux/actions/homepageActions';
import useDebouncedSearch from '../../helpers/debounce';
import PlayCardMobileHoverCard from "../../components/playCard/playCardMobileHoverCard";
import LoadingComponent from '../loader/LoadingComponent';
import { mapToDropdownArray } from '../../utils';
import { axiosApiInstance, BASE_URL, S3_BASE_URL } from '../../helpers/helper';
import Carousel from 'react-multi-carousel';

import greenStar from "../../assets/icons/green-stars.png"
import PlayCard from '../playCard/playCard_new';

import { FaAngleDown } from "react-icons/fa6";
import { gameAllCategory } from '../../redux/actions/gameDetailAction';
import ViewAllGamesFilterModal from '../modal/ViewAllGamesFilterModal';
import iconfilter from "../../assets/icons/iconfilter.png";
import WebinarDescriptionModal from '../modal/webinardescriptionmodal';
import WebinarPlayCard from '../playCard/webinarPlayCard';


const DefaultGames = (props) => {
    const { allGamesNonLogin } = props;
    const dispatch = useDispatch();
    const [openShareModal, setOpenShareModal] = useState(false);
    const [shareLink, setShareLink] = useState("");
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [page, setPage] = useState(1);
    const [loaded, setLoaded] = useState(false);
    const [openMobileHoverCard, setOpenMobileHoverCard] = useState(false);
    const [gameDetails, setGameDetails] = useState({});

    const [allExperience, setAllExperience] = useState([])

    const [bookEventCategories, setBookEventCategories] = useState([]);
    const [categoryGames, setCategoryGames] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [catMainTitle, setCatMainTitle] = useState('All')
    const [gamesDuration, setGamesDuration] = useState(0);
    const [gamesFormat, setGamesFormat] = useState('');
    const [isDuration, setIsDuration] = useState(false);
    const [isFormat, setIsFormat] = useState(false);
    const [openViewGamesFilter, setOpenViewGamesFilter] = useState(false);
    const [filterCount, setFilterCount] = useState([]);

    const [paginationDataGames, setpaginationDataGames] = useState();

    const [webinarDetails, setWeinarDetails] = useState(null);
    const [activeCategoryName, setActiveCategoryName] = useState(null);
    const [openWebinarDescriptionModal, setOpenWebinarDescriptionModal] = useState(false);

    const DefaultGames = useSelector(state => state.allGames);
    let { allGames } = DefaultGames;
    const { gameCategory } = useSelector((state) => state.gameAllCategory);

    const [isMobile, setIsMobile] = useState(false);

    const useSearch = () => useDebouncedSearch(text => searchGames(text));
    const searchGames = async (text) => {
        if (allGamesNonLogin) {
            // fetchAllGamesData(text, catMainTitle, gamesDuration, gamesFormat, page)

        }
        else {
            await dispatch(getAllGames(true, text, false, 1));
            setPage(1);
        }
    }
    const { searchText, setSearchText, results } = useSearch();

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 464);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        handleResize();
        dispatch(gameAllCategory())
        window.addEventListener('resize', handleResize);

        return () => {
            // Cleanup listener on unmount
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    const componentStyleValue = {
        mobile: "md:!w-[190px] md:!h-[280px] sm:!w-[155px] sm:!h-[238px] lg:!w-[200px] lg:!h-[300px] !w-[155px] !h-[238px]",
    }


    useEffect(() => {

        if (allGamesNonLogin && gameCategory) {
            // fetchAllGamesData(searchText, catMainTitle, gamesDuration, gamesFormat, page)
            let allGames = fetchAllExperienceGames();
            // let allGames;
            // if(categoryGames && categoryGames.length ===  0){
            //     allGames = games
            // }
            // else{
            //     allGames = categoryGames;
            // }

            if (searchText && allGames && allGames.length > 0) {
                allGames = allGames.filter(game =>
                    game.title.toLowerCase().includes(searchText.toLowerCase())
                );
                let perPageGames;
                let pageData;
                if (allGames && allGames.length > 0) {
                    allGames.sort((a, b) => a.title.localeCompare(b.title));
    
                    const { data, paginationData } = pagination({
                        data: allGames,
                        perPage: 15,
                        currentPage: page,
                        getAll: false
                    });
                    perPageGames = data;
                    pageData = paginationData;
                }
    
                if (searchText && searchText !== "") {
                    setpaginationDataGames(allGames);
                    // setCategoryGames(allGames)
                     allGames = allGames
                } else {
                    setpaginationDataGames(pageData);
                    // setCategoryGames(pageData)
                    allGames= perPageGames
                }
            }
            if (gamesFormat && allGames && allGames.length > 0) {
                allGames = allGames.filter(game => game.gameType === gamesFormat); 

                let perPageGames;
                let pageData;
                if (allGames && allGames.length > 0) {
                    allGames.sort((a, b) => a.title.localeCompare(b.title));
    
                    const { data, paginationData } = pagination({
                        data: allGames,
                        perPage: 15,
                        currentPage: page,
                        getAll: false
                    });
                    perPageGames = data;
                    pageData = paginationData;
                }
    
                if (searchText && searchText !== "") {
                    setpaginationDataGames(allGames);
                    // setCategoryGames(allGames)
                     allGames = allGames
                } else {
                    setpaginationDataGames(pageData);
                    // setCategoryGames(pageData)
                    allGames= perPageGames
                }

            }
            if (gamesFormat) {
                toggleGamesDurationInFilterCount("gamesFormat")
            }
            else {
                setFilterCount((prevState) => {
                    // Check if "gamesDuration" is already in the array
                    if (prevState.includes("gamesFormat")) {
                        // If present, remove it by filtering it out
                        return prevState.filter((item) => item !== "gamesFormat");
                    } else {
                        // If not present, add it to the array
                        return prevState;
                    }
                });
            }

            if (searchText && gamesFormat && allGames && allGames.length > 0) {
                allGames = allGames.filter(game =>
                    game.title.toLowerCase().includes(searchText.toLowerCase())
                );
            }

            if (gamesDuration && allGames && allGames.length > 0) {
                const filteredAndSortedGames = allGames
                    .filter((game) => game.duration <= Number(gamesDuration)) // Filter games with duration <= gamesDuration
                    .sort((a, b) => (b.duration || 0) - (a.duration || 0)); // Sort by duration in descending order

                allGames = filteredAndSortedGames;

                 let perPageGames;
                let pageData;
                if (allGames && allGames.length > 0) {
                    allGames.sort((a, b) => a.title.localeCompare(b.title));
    
                    const { data, paginationData } = pagination({
                        data: allGames,
                        perPage: 15,
                        currentPage: page,
                        getAll: false
                    });
                    perPageGames = data;
                    pageData = paginationData;
                }
    
                if (searchText && searchText !== "") {
                    setpaginationDataGames(allGames);
                    // setCategoryGames(allGames)
                     allGames = allGames
                } else {
                    setpaginationDataGames(pageData);
                    // setCategoryGames(pageData)
                    allGames= perPageGames
                }

            }

            if (gamesDuration) {
                toggleGamesDurationInFilterCount("gamesDuration")

            }
            else {
                setFilterCount((prevState) => {
                    // Check if "gamesDuration" is already in the array
                    if (prevState.includes("gamesDuration")) {
                        // If present, remove it by filtering it out
                        return prevState.filter((item) => item !== "gamesDuration");
                    } else {
                        // If not present, add it to the array
                        return prevState;
                    }
                });
            }

            // const { data, paginationData } = pagination({
            //     data: allGames,
            //     perPage: 15,
            //     currentPage: page,
            //     getAll
            //   });
            //   setpaginationDataGames(paginationData)
            setCategoryGames(allGames);

        }
        else if (allGamesNonLogin && categoryGames && categoryGames.length === 0 && searchText === '') {
            let games = fetchAllExperienceGames();

            let perPageGames;
            let pageData;
            if (games && games.length > 0) {
                games.sort((a, b) => a.title.localeCompare(b.title));

                const { data, paginationData } = pagination({
                    data: games,
                    perPage: 15,
                    currentPage: page,
                    getAll: false
                });
                perPageGames = data;
                pageData = paginationData;
            }

            if (searchText && searchText !== "") {
                setpaginationDataGames(games);
                setCategoryGames(games)
            } else {
                setpaginationDataGames(pageData);
                setCategoryGames(perPageGames)
            }

        }
        else {
            dispatch(getAllGames(true, searchText, false, page));
        }
    }, [page, searchText, gamesDuration, gamesFormat]);


    useEffect(() => {
        if (!Array.isArray(catMainTitle) && catMainTitle && catMainTitle !== "All") {
            // setGamesDuration(0);
            // setSearchText('');
            // setGamesFormat('');

            toggleGamesDurationInFilterCount("catMainTitle")
        }
        else if (Array.isArray(catMainTitle) && catMainTitle.length > 0 && catMainTitle[0].title === "All" || Array.isArray(catMainTitle) && catMainTitle.length > 0) {

            toggleGamesDurationInFilterCount("catMainTitle")

        }
        else {

            setFilterCount((prevState) => {
                // Check if "gamesDuration" is already in the array
                if (prevState.includes("catMainTitle")) {
                    // If present, remove it by filtering it out
                    return prevState.filter((item) => item !== "catMainTitle");
                } else {
                    // If not present, add it to the array
                    return prevState;
                }
            });
        }

        if (allGamesNonLogin && gameCategory && gameCategory?.data) {

            let filterExperienceData = mapToDropdownArray(gameCategory.data, 'header')

            setBookEventCategories(filterExperienceData);
            setPage(1)
            let fetchGamesData = fetchAllExperienceGames();

            let perPageGames;
            let pageData;
            if (fetchGamesData && fetchGamesData.length > 0) {
                fetchGamesData.sort((a, b) => a.title.localeCompare(b.title));

                const { data, paginationData } = pagination({
                    data: fetchGamesData,
                    perPage: 15,
                    currentPage: page,
                    getAll: false
                });
                perPageGames = data;
                pageData = paginationData;
            }

            if (searchText && searchText !== "") {
                setpaginationDataGames(fetchGamesData);
                setCategoryGames(fetchGamesData)

            } else {
                setpaginationDataGames(pageData);
                setCategoryGames(perPageGames)
                
            }


        }
    }, [gameCategory, catMainTitle])


    // const fetchAllExperienceGames = () => {
    //     setLoaded(false)
    //     const combinedData = gameCategory?.data
    //         .filter(category => catMainTitle === "All" && category.mainTitle !== null || category.mainTitle === catMainTitle && catMainTitle !== "All")
    //         .reduce((acc, category) => {
    //             const existingCategory = acc.find(item => item.mainTitle === category.mainTitle);

    //             // Sort games by duration descending if `gameduration` query is present
    //             const sortedGames = gamesDuration
    //                 ? category.games.sort((a, b) => (b.duration || 0) - (a.duration || 0))
    //                 : category.games;

    //             if (existingCategory) {
    //                 // Merge games while avoiding duplicates
    //                 existingCategory.titles.push(category.title);
    //                 existingCategory.games = [
    //                     ...existingCategory.games,
    //                     ...sortedGames.filter(
    //                         game => !existingCategory.games.some(existingGame => existingGame.id === game.id)
    //                     )
    //                 ];
    //             } else {
    //                 // Add new category with games to accumulator
    //                 acc.push({
    //                     id: category?.id,
    //                     mainTitle: category.mainTitle,
    //                     titles: [category.title],
    //                     games: sortedGames,
    //                     svgPath: category.svgPath,
    //                     thumbnail: category.thumbnail,
    //                 });
    //             }
    //             return acc;
    //         }, []);

    //     const allGames = combinedData && combinedData.length > 0 && combinedData.reduce((allGamesAcc, category) => {
    //         category.games.forEach(game => {
    //             if (!allGamesAcc.some(existingGame => existingGame.id === game.id)) {
    //                 allGamesAcc.push(game);
    //             }
    //         });
    //         return allGamesAcc;
    //     }, []);

    //     let perPageGames;
    //     let pageData;
    //     if (allGames && allGames.length > 0) {
    //         allGames.sort((a, b) => a.title.localeCompare(b.title));

    //         const { data, paginationData } = pagination({
    //             data: allGames,
    //             perPage: 15,
    //             currentPage: page,
    //             getAll: false
    //         });
    //         perPageGames = data;
    //         pageData = paginationData
    //     }

    //     if (searchText && searchText !== "") {
    //         setpaginationDataGames(allGames)
    //     }
    //     else {
    //         setpaginationDataGames(pageData)
    //     }

    //     setLoaded(true)

    //     if (searchText && searchText !== "") {
    //         return allGames
    //     }
    //     else {
    //         return perPageGames

    //     }
    // }

    const fetchAllExperienceGames = () => {
        setLoaded(false);

        // Determine if catMainTitle is an array or a string
        const isCatMainTitleArray = Array.isArray(catMainTitle);

        const combinedData = gameCategory?.data
            .filter(category => {
                if (Array.isArray(catMainTitle)) {
                    if (catMainTitle.some(data => data.title === "All")) {
                        return category.mainTitle !== null;
                    }
                    return catMainTitle.some(data => data.title === category.mainTitle);
                } else {
                    return (
                        (catMainTitle === "All" && category.mainTitle !== null) ||
                        (category.mainTitle === catMainTitle && catMainTitle !== "All")
                    );
                }
            })
            .reduce((acc, category) => {
                const existingCategory = acc.find(item => item.mainTitle === category.mainTitle);

                // Sort games by duration descending if `gamesDuration` query is present
                const sortedGames = gamesDuration
                    ? category.games.sort((a, b) => (b.duration || 0) - (a.duration || 0))
                    : category.games;

                if (existingCategory) {
                    // Merge games while avoiding duplicates
                    existingCategory.titles.push(category.title);
                    existingCategory.games = [
                        ...existingCategory.games,
                        ...sortedGames.filter(
                            game => !existingCategory.games.some(existingGame => existingGame.id === game.id)
                        )
                    ];
                } else {
                    // Add new category with games to accumulator
                    acc.push({
                        id: category?.id,
                        mainTitle: category.mainTitle,
                        titles: [category.title],
                        games: sortedGames,
                        svgPath: category.svgPath,
                        thumbnail: category.thumbnail,
                    });
                }
                return acc;
            }, []);

        const allGames = combinedData && combinedData.length > 0 && combinedData.reduce((allGamesAcc, category) => {
            category.games.forEach(game => {
                if (!allGamesAcc.some(existingGame => existingGame.id === game.id)) {
                    allGamesAcc.push(game);
                }
            });
            return allGamesAcc;
        }, []);

        // let perPageGames;
        // let pageData;
        // if (allGames && allGames.length > 0) {
        //     allGames.sort((a, b) => a.title.localeCompare(b.title));

        //     const { data, paginationData } = pagination({
        //         data: allGames,
        //         perPage: 15,
        //         currentPage: page,
        //         getAll: false
        //     });
        //     perPageGames = data;
        //     pageData = paginationData;
        // }

        // if (searchText && searchText !== "") {
        //     setpaginationDataGames(allGames);
        // } else {
        //     setpaginationDataGames(pageData);
        // }

        setLoaded(true);

        // if (searchText && searchText !== "") {
        return allGames;
        // } else {
        //     return perPageGames;
        // }
    };


    useEffect(() => {
        if (
            (DefaultGames && DefaultGames.loading) ||
            (results && results.loading)
        ) {
            setLoaded(false);
        }
        else
            setLoaded(true);
    }, [DefaultGames, results]);
    useEffect(() => {
        if (props.location && props.location.search) {
            const page = new URLSearchParams(props.location.search).get('page');
            if (page > 1)
                setPage(parseInt(page));
        }
    }, [props.location]);
    const changePage = (newPage) => {
        setLoaded(false);
        setPage(newPage)
        props.history.push('/manage-games/default?page=' + newPage);
        setTimeout(() => {
            setLoaded(true)
        }, 100);
    }

    const allGamesChangePage = (newPage) => {
        setLoaded(false);
        setPage(newPage)
        setTimeout(() => {
            setLoaded(true)
        }, 100);
    }
    const handleChange = async (e) => {
        setSearchText(e.target.value);
        // if (e.target.value.length === 0) {
        //     await dispatch(getAllGames(true, ""));
        //     setPage(1);
        // }
    }


    const handleExperienceClick = (e, title) => {
        e.preventDefault();
        setCatMainTitle(title)
    }


    function CardImage({ src, classValues, indx }) {
        const [imageLoaded, setImageLoaded] = useState(false);

        return (

            <img
                src={`${S3_BASE_URL + src}`}
                alt={`Card ${indx} Image`}
                className={`${classValues} ${imageLoaded ? 'opacity-100' : ' bg-slate-100 skeleton-animation'}`}
                onLoad={() => setImageLoaded(true)}
            />

        );
    }

    const handleDurationFilterCheckboxChange = (e, filterValue) => {
        e.stopPropagation();
        // e.preventDefault();

        if (e.target.checked) {
            if (gamesDuration === filterValue) {
                e.preventDefault();
                setGamesDuration(0);

            }
        } else {
            setGamesDuration(filterValue);
        }
    };

    const handleDurationFilterCheckboxChangeMobile = (e, filterValue) => {
        e.stopPropagation();
        // e.preventDefault();

        if (gamesDuration === filterValue) {
            e.preventDefault();
            setGamesDuration(0);

        }
        else {
            setGamesDuration(filterValue);
        }
    };

    const handleSearchDuration = async (e, filterValue) => {
        if (gamesDuration === filterValue) {
            e.preventDefault();
            setGamesDuration(0);

        }
        else {
            setGamesDuration(filterValue);
        }
    }

    const handleFormatFilterCheckboxChange = (e, filterValue) => {
        e.stopPropagation();
        // e.preventDefault();

        if (e.target.checked) {
            if (gamesFormat === filterValue) {
                e.preventDefault();
                setGamesFormat('');

            }
        } else {
            setGamesFormat(filterValue);
        }
    };

    const handleFormatFilterCheckboxChangeMobile = (e, filterValue) => {
        e.stopPropagation();
        // e.preventDefault();

        if (gamesFormat === filterValue) {
            e.preventDefault();
            setGamesFormat('');

        }
        else {
            setGamesFormat(filterValue);
        }
    };

    const handleSearchFormat = async (e, filterValue) => {
        if (gamesFormat === filterValue) {
            e.preventDefault();
            setGamesFormat('');

        }
        else {
            setGamesFormat(filterValue);
        }
    }

    const durationClick = () => {
        setIsDuration(!isDuration)

    }
    const formatClick = () => {
        setIsFormat(!isFormat)
    }


    const dropdownRef = useRef(null);
    const formatDropdownRef = useRef(null);

    const dropdownRefMob = useRef(null);
    const formatDropdownRefMob = useRef(null);

    // Function to handle clicks outside of the dropdown
    const handleClickOutside = (e) => {

        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsDuration(false);
        }
        else if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
            durationClick()
        }

        if (
            dropdownRefMob.current &&
            !dropdownRefMob.current.contains(e.target) && isMobile
        ) {
            setIsDuration(false);

        }
    };

    const handleClickFormatOutside = (e) => {

        if (!isMobile && formatDropdownRef.current && !formatDropdownRef.current.contains(e.target)) {
            setIsFormat(false);

        }
        else if (formatDropdownRef.current && formatDropdownRef.current.contains(e.target)) {
            formatClick();
        }

        if (
            formatDropdownRefMob.current &&
            !formatDropdownRefMob.current.contains(e.target) && isMobile
        ) {
            setIsFormat(false);

        }
    };

    // Add event listener when component mounts and remove it on unmount
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {

        document.addEventListener('mousedown', handleClickFormatOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickFormatOutside);
        };
    }, []);

    const handleFilterClick = () => {
        setOpenViewGamesFilter(true)
    }

    const toggleGamesDurationInFilterCount = (ele) => {
        setFilterCount((prevState) => {
            // Check if "gamesDuration" is already in the array
            if (prevState.includes(ele)) {
                // If present, remove it by filtering it out
                return prevState;
            } else {
                // If not present, add it to the array
                return [...prevState, ele];
            }
        });
    };


    const pagination = ({ data, perPage, currentPage, getAll = false }) => {
        try {
            perPage = Number(perPage);
            currentPage = Number(currentPage);
            if (getAll) {
                return {
                    data,
                    paginationData: {
                        currentPage: 1,
                        perPage: data.length,
                        totalEntries: data.length
                    }
                };
            }
            let tableDataCount = data.length;
            let paginationItemsCount =
                tableDataCount % perPage === 0
                    ? tableDataCount / perPage
                    : tableDataCount / perPage + 1;
            paginationItemsCount = Math.floor(paginationItemsCount);
            let currPage = currentPage > paginationItemsCount ? 1 : currentPage;
            return {
                data: data.slice((currPage - 1) * perPage, currPage * perPage),
                paginationData: {
                    currentPage: currPage,
                    perPage: perPage,
                    totalEntries: data.length
                }
            };
        } catch (error) {
            console.log(`"ERROR => ${error}"`);
            throw { error };
        }
    };

    const getMoreWebinars = () => {
        return categoryGames
            .filter(item => item.isWebinar === true) // Filter to get only webinars
            .filter(webinar => webinar.categoryId === webinarDetails.categoryId) // Match categoryId
            .slice(0, 4); // Limit to first 4 items
    }

    const clickFunction = (webinar) => {
        setWeinarDetails(webinar);
        setOpenWebinarDescriptionModal(true);
    }

    return (
        <div className={` ${allGamesNonLogin && allGamesNonLogin?.data?.length > 0 ? ' min-h-[100%] h-full max-h-max pl-[0px] pr-[0px] md:pl-[10px] md:pr-[10px] lg:pl-[110px] lg:pr-[110px]' : ''}`}>

            {
                allGamesNonLogin && allGamesNonLogin?.data?.length > 0 ? (
                    <>
                        <div className="top-[-300px] right-[100px] z-0 fixed md:flex lg:flex hidden w-[870px] h-[860px]"
                            style={{
                                background: 'radial-gradient(circle, rgb(245, 232, 227, 0.8) -29%, rgb(253, 248, 235, 0.5) 28%, rgb(255, 255, 255, 0) 72%)'
                            }}
                        ></div>

                        <div className="top-[330px] left-[-200px] z-0 fixed md:flex lg:flex hidden w-[470px] h-[460px]"
                            style={{
                                background: 'radial-gradient(circle, rgb(282, 194, 217) -43%, rgb(279, 232, 235) 19%, rgb(255, 255, 255) 69%)'
                            }}
                        ></div>
                    </>
                )
                    : null
            }
            {allGamesNonLogin && allGamesNonLogin?.data?.length > 0 ? (
                <div className={loaded ? "manage-games z-[80] w-full h-auto" : "manage-games loading z-[80] "}>

                    <div className="flex justify-center items-center w-full">
                        <h1 className='relative font-bold text-[18px] lg:text-[40px]'>
                            <img
                                src={greenStar}
                                alt="Decoration"
                                className="top-[-10px] right-[-30px] absolute w-[25px] h-[25px] object-contain"
                            />
                            Explore all our <span className='font-bold text-[18px] text-[var(--color-theme)] lg:text-[40px]'>experiences</span>
                        </h1>
                    </div>
                    <section className={`section ${isMobile ? 'mt-[45px]' : 'mt-[70px]'} ${isMobile ? 'mb-[45px]' : `mb-[100px]`} mx-auto max-w-[100%]`}>
                        {!isMobile && (
                            <Carousel
                                autoPlay={false}
                                additionalTransfrom={0}
                                arrows={true}
                                autoPlaySpeed={3000}
                                centerMode={false}
                                className={` relative ${bookEventCategories.length < 1 ? 'flex justify-center' : ''}`} // Add conditional classes
                                containerClass="container-with-dots"
                                draggable
                                // infinite
                                itemClass="carousel-item"
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                responsive={{
                                    desktop: {
                                        breakpoint: { max: 3000, min: 1400 },
                                        items: 6,
                                        partialVisibilityGutter: 40,
                                    },
                                    semi_medium: {
                                        breakpoint: { max: 1400, min: 900 },
                                        items: 4,
                                        partialVisibilityGutter: 30,
                                    },
                                    medium: {
                                        breakpoint: { max: 900, min: 464 },
                                        items: 3.5,
                                        partialVisibilityGutter: 30,
                                    },
                                }}
                                slidesToSlide={1}
                                swipeable
                            >
                                <div key={0} className="flex flex-col justify-center items-center mx-2 cursor-pointer"
                                    onClick={(e) => handleExperienceClick(e, 'All')}

                                >
                                    <div className="flex justify-center items-center border-[3px] border-gray-400 bg-gray-100 rounded-full w-[77px] md:w-[150px] h-[77px] md:h-[150px]">

                                        <h1 className='font-bold text-[18px] text-gray-500 md:text-[32px] lg:text-[40px]'>All</h1>
                                    </div>
                                    <h5
                                        className={`mt-3 text-[16px] text-center font-bold capitalize ${catMainTitle === 'All' ? 'underline underline-offset-2' : ''}
                                        ${Array.isArray(catMainTitle) ? catMainTitle.some(data => data.title === "All")
                                                ? 'underline underline-offset-2'
                                                : ''
                                                : ''
                                            }
                                         
                                        `}
                                    >All</h5>
                                </div>
                                {bookEventCategories && bookEventCategories.length > 0 ? bookEventCategories.map((logo, index) => (
                                    <div key={index} className="flex flex-col justify-center items-center mx-2 cursor-pointer"
                                        onClick={(e) => handleExperienceClick(e, logo.mainTitle)}
                                    >
                                        {
                                            logo && logo?.thumbnail && /\.(gif|jpg|jpeg|png)$/.test(logo.thumbnail) ? (

                                                <CardImage src={logo.thumbnail} classValues={"md:w-[150px] md:h-[150px] w-[100px] h-[100px] rounded-full object-cover"} indx={index} />
                                            ) : (
                                                <div className="bg-slate-100 rounded-full w-[100px] md:w-[150px] h-[100px] md:h-[150px] skeleton-animation"></div>

                                            )
                                        }

                                        <h5
                                            className={`mt-3 text-[16px] text-center font-bold capitalize ${catMainTitle === logo.mainTitle ? 'underline underline-offset-2' : ''}
                                             ${Array.isArray(catMainTitle)
                                                    ? catMainTitle.some(item => item.title === logo.mainTitle)
                                                        ? "underline underline-offset-2"
                                                        : ''
                                                    : catMainTitle === logo.mainTitle
                                                        ? "underline underline-offset-2"
                                                        : ''
                                                }
                                            `}
                                        >{logo.mainTitle === "DEIB - Diversity, Inclusion, Equity and Belonging" ? "DEIB" : logo.mainTitle}</h5>
                                    </div>
                                ))
                                    : Array.from({ length: 6 }).map((_, index) => (
                                        <div key={index} className="flex flex-col justify-center items-center mx-2 cursor-pointer">
                                            <div className="bg-slate-100 rounded-full w-[100px] md:w-[150px] h-[100px] md:h-[150px] skeleton-animation"></div>


                                            <h5
                                                className={`mt-3 w-[103px] h-[28px] bg-slate-100 skeleton-animation"`}
                                            ></h5>
                                        </div>
                                    ))
                                }
                            </Carousel>
                        )}

                        {isMobile && (
                            <Carousel
                                autoPlay={false}
                                additionalTransfrom={0}
                                arrows={false}
                                autoPlaySpeed={3000}
                                centerMode={false}
                                className={` relative ${bookEventCategories.length < 1 ? 'flex justify-center' : ''}`} // Adjust for mobile
                                containerClass="container-with-dots"
                                draggable
                                // infinite
                                itemClass="carousel-item"
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                responsive={{
                                    mobile: {
                                        breakpoint: { max: 464, min: 0 },
                                        items: 3.1,
                                        partialVisibilityGutter: 30,
                                    },
                                }}
                                slidesToSlide={1}
                                swipeable
                            >
                                <div key={0} className="flex flex-col justify-center items-center mx-2 cursor-pointer"
                                    onClick={(e) => handleExperienceClick(e, 'All')}

                                >
                                    <div className="flex justify-center items-center border-[3px] border-gray-400 bg-gray-100 rounded-full w-[77px] md:w-[150px] h-[77px] md:h-[150px]">

                                        <h1 className='font-bold text-[18px] text-gray-500 md:text-[32px] lg:text-[40px]'>All</h1>
                                    </div>
                                    <h5
                                        className={`mt-3 text-[14px] text-center font-bold capitalize ${catMainTitle === "All" ? 'underline underline-offset-2' : ''}
                                          ${Array.isArray(catMainTitle) ? catMainTitle.some(data => data.title === "All")
                                                ? 'underline underline-offset-2'
                                                : ''
                                                : ''
                                            }
                                        `}
                                    >All</h5>
                                </div>
                                {bookEventCategories && bookEventCategories.length > 0 ? bookEventCategories.map((logo, index) => (
                                    <div key={index} className="flex flex-col justify-center items-center mx-2 cursor-pointer"
                                        onClick={(e) => handleExperienceClick(e, logo.mainTitle)}

                                    >

                                        {
                                            logo && logo?.thumbnail && /\.(gif|jpg|jpeg|png)$/.test(logo.thumbnail) ? (

                                                <CardImage src={logo.thumbnail} classValues={"md:w-[150px] md:h-[150px]  w-[77px] h-[77px] rounded-full object-cover"} indx={index} />

                                            ) : (
                                                <div className="bg-slate-100 rounded-full w-[77px] md:w-[150px] h-[77px] md:h-[150px] skeleton-animation"></div>

                                            )
                                        }
                                        <h5
                                            className={`mt-3 text-[14px] text-center font-bold capitalize ${catMainTitle === logo.mainTitle ? 'underline underline-offset-2' : ''}
                                            ${Array.isArray(catMainTitle)
                                                    ? catMainTitle.some(item => item.title === logo.mainTitle)
                                                        ? "underline underline-offset-2"
                                                        : ''
                                                    : catMainTitle === logo.mainTitle
                                                        ? "underline underline-offset-2"
                                                        : ''
                                                }
                                            `}
                                        >{logo.mainTitle === "DEIB - Diversity, Inclusion, Equity and Belonging" ? "DEIB" : logo.mainTitle}</h5>
                                    </div>
                                ))
                                    : Array.from({ length: 6 }).map((_, index) => (
                                        <div key={index} className="flex flex-col justify-center items-center mx-2 cursor-pointer">
                                            <div className="bg-slate-100 rounded-full w-[77px] md:w-[150px] h-[77px] md:h-[150px] skeleton-animation"></div>


                                            <h5
                                                className={`mt-3 w-[70px] h-[25px] bg-slate-100 skeleton-animation"`}
                                            ></h5>
                                        </div>
                                    ))
                                }
                            </Carousel>
                        )}
                    </section>
                    {/* <div className="flex lg:justify-between profile-section">
                        <h5 className="profile-name">{allGamesNonLogin && categoryGames?.length > 0 ? 'All Games' : 'Default Games'}</h5>
                        <div className="input-icon">
                            <img src={search} alt="search" />
                            <input type='text' name='search' value={searchText} onChange={handleChange} placeholder="Search a game" />
                        </div>
                    </div> */}
                    <div className="flex flex-row justify-around items-center lg:hidden pb-[10px] w-full">
                        <div className="relative z-[80] flex flex-row justify-center items-center lg:hidden bg-[#FDF1F3] rounded-[6px] w-[88px] h-[38px] cursor-pointer"
                            onClick={handleFilterClick}
                            style={{
                                boxShadow: '0px 3px 6px #00000029'
                            }}
                        >
                            <img
                                src={iconfilter}
                                alt="Filter Icon"
                                className="mr-2 w-[15px] h-[15px]"
                            />
                            <span className='font-semibold text-[14px]'>Filter</span>
                            {
                                filterCount && filterCount.length > 0 && (
                                    <div className="top-[-8px] right-[-10px] absolute flex justify-center items-center bg-sky-400 rounded-full w-[25px] h-[25px] text-white">{filterCount.length}</div>

                                )
                            }

                        </div>



                        <div className="relative z-[80] flex justify-center items-center bg-[#FDF1F3] rounded-[6px] w-[88px] h-[38px] cursor-pointer"
                            style={{
                                boxShadow: '0px 3px 6px #00000029'
                            }}
                            ref={isMobile ? formatDropdownRefMob : null}
                            onClick={formatClick}
                        >
                            <span className='font-semibold text-[14px]'>Format</span>
                            <FaAngleDown className='ml-[8px] w-[15px] h-[15px]' />

                            {
                                isFormat && (
                                    <div className="bottom-[-105px] z-[200] absolute flex flex-col justify-center bg-white rounded-[6px] w-full h-auto"
                                        style={{
                                            boxShadow: '0px 3px 6px #00000029'
                                        }}
                                    >
                                        {
                                            ["ONLINE", "OFFLINE", "HYBRID"].map((val, index) => (
                                                <div className="flex items-center gap-[8px] hover:bg-gray-200 pt-2 pb-2 pl-3 w-full cursor-pointer"
                                                    onClick={(e) => handleFormatFilterCheckboxChangeMobile(e, val)}
                                                    style={{
                                                        background: gamesFormat === val ? 'rgb(223 178 244)' : ''
                                                    }}
                                                >
                                                    {/* <div >
                                                        <label class="checkbox-label">
                                                            <input type="checkbox" class={`${gamesFormat} checkbox-tow`}
                                                                checked={gamesFormat === val ? true : false}
                                                                onChange={(e) => handleFormatFilterCheckboxChange(e, val)}
                                                            />
                                                            <div class="svg-icon">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    height="16px"
                                                                    viewBox="0 0 448 512"
                                                                >
                                                                    <path
                                                                        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                            <span class="check-box-container"></span>
                                                        </label>
                                                    </div> */}

                                                    {/* </label> */}
                                                    <div className={` pl-2 text-[14px] ${gamesFormat === val ? `font-bold` : `font-normal`} text-center  text-gray750 heading `} id='duration-text-cont' style={{ fontSize: gamesFormat === val ? '0.930rem' : '0.875rem' }}>
                                                        {val === "ONLINE" ? "Virtual" : val === "OFFLINE" ? "Onsite" : "Hybird"}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                            }
                        </div>

                        <div className="relative z-[80] flex justify-center items-center bg-[#FDF1F3] rounded-[6px] w-[88px] h-[38px] cursor-pointer"
                            onClick={durationClick}
                            ref={isMobile ? dropdownRefMob : null}
                            style={{
                                boxShadow: '0px 3px 6px #00000029'
                            }}
                        >
                            <span className='font-semibold text-[14px]'>Duration</span>
                            <FaAngleDown className='ml-[4px] sm:ml-[8px] md:ml-[8px] lg:ml-[8px] w-[15px] h-[15px]' />
                            {
                                isDuration && (
                                    <div className="bottom-[-230px] z-50 absolute flex flex-col justify-center bg-white rounded-[6px] w-full h-auto"
                                        style={{
                                            boxShadow: '0px 3px 6px #00000029'
                                        }}

                                    >
                                        {
                                            [5, 10, 20, 30, 40, 50, 60].map((val, index) => (
                                                <div className="flex items-center gap-[8px] hover:bg-gray-200 pt-2 pb-2 pl-3 w-full cursor-pointer"
                                                    onClick={(e) => handleDurationFilterCheckboxChangeMobile(e, val)}
                                                    style={{
                                                        background: gamesDuration == val ? 'rgb(223 178 244)' : ''
                                                    }}
                                                >
                                                    {/* <div >
                                                        <label class="checkbox-label">
                                                            <input type="checkbox" class={`${gamesDuration} checkbox-tow`}
                                                                checked={gamesDuration == val ? true : false}
                                                                onChange={(e) => handleDurationFilterCheckboxChange(e, val)}
                                                            />
                                                            <div class="svg-icon">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    height="16px"
                                                                    viewBox="0 0 448 512"
                                                                >
                                                                    <path
                                                                        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                            <span class="check-box-container"></span>
                                                        </label>
                                                    </div> */}

                                                    {/* </label> */}
                                                    <div className={` pl-2 text-[14px] ${gamesDuration === val ? `font-bold` : `font-normal`} text-center  text-gray750 heading `} id='duration-text-cont' style={{ fontSize: gamesDuration === val ? '0.930rem' : '0.875rem' }}>
                                                        {val} min
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                            }

                        </div>
                    </div>
                    <div className="z-0 flex lg:hidden ml-[10px] pb-[35px] w-full">
                        <div className="z-[0] flex items-center mt-3 md:mt-0 lg:mt-0 !w-full md:!w-[310px] lg:!w-[430px] h-[47px]">
                            <div className="z-[0] !w-full md:!w-[310px] lg:!w-[430px] input-icon">
                                <img src={search} alt="search" />
                                <input type='text' name='search' value={searchText} onChange={handleChange} placeholder="Search a game" className='z-[80]' />
                            </div>
                        </div>
                    </div>

                    <div className="lg:flex md:flex-row lg:flex-row flex-col justify-between items-center hidden ml-[10px] pb-[35px] w-full">
                        <div className="relative z-[80] lg:flex flex-row justify-center items-center hidden bg-[#FDF1F3] rounded-[6px] w-[140px] h-[47px] cursor-pointer"
                            onClick={handleFilterClick}
                            style={{
                                boxShadow: '0px 3px 6px #00000029'
                            }}
                        >
                            <img
                                src={iconfilter}
                                alt="Filter Icon"
                                className="mr-2"
                            />
                            <span className='font-semibold text-[16px]'>Filter</span>
                            {
                                filterCount && filterCount.length > 0 && (
                                    <div className="top-[-8px] right-[-10px] absolute flex justify-center items-center bg-sky-400 rounded-full w-[25px] h-[25px] text-white">{filterCount.length}</div>

                                )
                            }

                        </div>

                        <div className="z-[80] flex items-center mt-3 md:mt-0 lg:mt-0 !w-full md:!w-[310px] lg:!w-[430px] h-[47px]">
                            <div className="z-[80] !w-full md:!w-[310px] lg:!w-[430px] input-icon">
                                <img src={search} alt="search" />
                                <input type='text' name='search' value={searchText} onChange={handleChange} placeholder="Search a game" className='z-[80]' />
                            </div>
                        </div>

                        <div className="lg:flex items-center hidden">

                            <div className="relative z-[80] flex justify-center items-center bg-[#FDF1F3] rounded-[6px] w-[140px] h-[47px] cursor-pointer"
                                style={{
                                    boxShadow: '0px 3px 6px #00000029'
                                }}
                                ref={!isMobile ? formatDropdownRef : null}
                            // onClick={formatClick}
                            >
                                <span className='font-semibold text-[16px]'>Format</span>
                                <FaAngleDown className='ml-[8px] w-[15px] h-[15px]' />

                                {
                                    isFormat && (
                                        <div className="bottom-[-145px] z-50 absolute flex flex-col justify-center bg-white rounded-[6px] w-full h-auto"
                                            style={{
                                                boxShadow: '0px 3px 6px #00000029'
                                            }}
                                        >
                                            {
                                                ["ONLINE", "OFFLINE", "HYBRID"].map((val, index) => (
                                                    <div className="flex items-center gap-[8px] hover:bg-gray-200 pt-2 pb-2 pl-3 w-full cursor-pointer"
                                                        onClick={(e) => handleSearchFormat(e, val)}
                                                        style={{
                                                            background: gamesFormat === val ? 'rgb(223 178 244)' : ''
                                                        }}
                                                    >
                                                        <div >
                                                            <label class="checkbox-label">
                                                                <input type="checkbox" class={`${gamesFormat} checkbox-tow`}
                                                                    checked={gamesFormat === val ? true : false}
                                                                    onChange={(e) => handleFormatFilterCheckboxChange(e, val)}
                                                                />
                                                                <div class="svg-icon">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        height="16px"
                                                                        viewBox="0 0 448 512"
                                                                    >
                                                                        <path
                                                                            d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                                                        ></path>
                                                                    </svg>
                                                                </div>
                                                                <span class="check-box-container"></span>
                                                            </label>
                                                        </div>

                                                        {/* </label> */}
                                                        <div className={` pl-2 text-sm ${gamesFormat === val ? `font-bold` : `font-normal`} text-center  text-gray750 heading `} id='duration-text-cont' style={{ fontSize: gamesFormat === val ? '0.930rem' : '0.875rem' }}>
                                                            {val === "ONLINE" ? "Virtual" : val === "OFFLINE" ? "Onsite" : "Hybird"}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    )
                                }
                            </div>

                            <div className="relative z-[80] flex justify-center items-center bg-[#FDF1F3] ml-3 rounded-[6px] w-[140px] h-[47px] cursor-pointer"
                                // onClick={durationClick}
                                ref={!isMobile ? dropdownRef : null}
                                style={{
                                    boxShadow: '0px 3px 6px #00000029'
                                }}
                            >
                                <span className='font-semibold text-[16px]'>Duration</span>
                                <FaAngleDown className='ml-[4px] sm:ml-[8px] md:ml-[8px] lg:ml-[8px] w-[15px] h-[15px]' />
                                {
                                    isDuration && (
                                        <div className="bottom-[-330px] z-50 absolute flex flex-col justify-center bg-white rounded-[6px] w-full h-auto"
                                            style={{
                                                boxShadow: '0px 3px 6px #00000029'
                                            }}
                                        >
                                            {
                                                [5, 10, 20, 30, 40, 50, 60].map((val, index) => (
                                                    <div className="flex items-center gap-[8px] hover:bg-gray-200 pt-2 pb-2 pl-3 w-full cursor-pointer"
                                                        onClick={(e) => handleSearchDuration(e, val)}
                                                        style={{
                                                            background: gamesDuration == val ? 'rgb(223 178 244)' : ''
                                                        }}
                                                    >
                                                        <div >
                                                            <label class="checkbox-label">
                                                                <input type="checkbox" class={`${gamesDuration} checkbox-tow`}
                                                                    checked={gamesDuration == val ? true : false}
                                                                    onChange={(e) => handleDurationFilterCheckboxChange(e, val)}
                                                                />
                                                                <div class="svg-icon">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        height="16px"
                                                                        viewBox="0 0 448 512"
                                                                    >
                                                                        <path
                                                                            d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                                                        ></path>
                                                                    </svg>
                                                                </div>
                                                                <span class="check-box-container"></span>
                                                            </label>
                                                        </div>

                                                        {/* </label> */}
                                                        <div className={` pl-2 text-sm ${gamesDuration === val ? `font-bold` : `font-normal`} text-center  text-gray750 heading `} id='duration-text-cont' style={{ fontSize: gamesDuration === val ? '0.930rem' : '0.875rem' }}>
                                                            {val} min
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    )
                                }


                            </div>

                        </div>
                    </div>
                    <div className="gap-y-8 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 w-full h-auto"
                        style={{
                            columnGap: 'calc((100% - (5 * 190px)) / 4)'
                        }}
                    >
                        {
                            loaded && loaded === true && categoryGames && categoryGames.length > 0 ? categoryGames.map((game, index) => {
                                return (

                                    // <div className="flex justify-center items-center mr-[20px] mb-5 md:w-[220px] lg:w-[220px] md:h-[320px] lg:h-[320px]">


                                    game?.isWebinar ? (
                                        <WebinarPlayCard
                                            key={JSON.stringify(game)}
                                            gameDetail={game}
                                            title={game.title}
                                            srcImage={[game.mobileCoverMedia]}
                                            setOpenShareModal={setOpenShareModal}
                                            setShareLink={setShareLink}
                                            isFromWebinar={true}
                                            webinarClickFunction={() => clickFunction(game)}
                                            setGameDetails={setGameDetails}
                                            setOpenMobileHoverCard={setOpenMobileHoverCard}
                                            componentStyle={componentStyleValue}
                                        ></WebinarPlayCard>

                                    )
                                        : (
                                            <PlayCard
                                                key={JSON.stringify(game)}
                                                gameDetail={game}
                                                title={game.title}
                                                srcImage={game.coverMedia}
                                                setOpenShareModal={setOpenShareModal}
                                                setShareLink={setShareLink}
                                                // setHover={setHover}
                                                // setHoverSlogan={() => setHoverSlogan(slogan.id)}
                                                setGameDetails={setGameDetails}
                                                setOpenMobileHoverCard={setOpenMobileHoverCard}
                                                componentStyle={componentStyleValue}
                                            />
                                        )

                                    // </div>

                                )
                            })
                                : !loaded ?
                                    Array.from({ length: 5 }).map((_, indx) => (
                                        <div className="z-[80] flex justify-center items-center mr-[20px] mb-5 rounded-[10px] md:w-[220px] lg:w-[220px] md:h-[320px] lg:h-[320px]">
                                            <div className="flex bg-slate-100 rounded-[10px] w-[200px] h-[300px] skeleton-animation">

                                            </div>
                                        </div>
                                    ))
                                    // : (
                                    //     <div className="no-game-wrapper">
                                    //         <div>
                                    //             <h3 className="no-game">
                                    //                 {searchText.length === 0
                                    //                     ? "Currently, there are no games under this Experiences."
                                    //                     : "Currently, there are no games with this name under this Experiences."}
                                    //             </h3>
                                    //             <span
                                    //                 className="no-game-span"
                                    //                 onClick={() => props.history.push("/")}
                                    //             >
                                    //                 Go to Homepage
                                    //             </span>
                                    //         </div>
                                    //     </div>
                                    // )
                                    : null
                        }
                    </div>
                    {
                        loaded && categoryGames && categoryGames.length === 0 || loaded && !categoryGames ? (
                            <div className="flex justify-center items-center w-full h-auto">
                                <div className="no-game-wrapper">
                                    <div>
                                        <h3 className="no-game">
                                            {searchText.length === 0
                                                ? "Currently, there are no games under this Experiences."
                                                : "Currently, there are no games with this name under this Experiences."}
                                        </h3>
                                        <span
                                            className="no-game-span"
                                            onClick={() => props.history.push("/")}
                                        >
                                            Go to Homepage
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )
                            : null
                    }

                    <div className="flex justify-center items-center mb-8 w-full">
                        {paginationDataGames?.totalEntries > 0 &&
                            <div className="pagination-wrapper">
                                <button
                                    className={page > 1 ? "pagination-left enable" : "pagination-left"}
                                    onClick={() => {
                                        if (page > 1)
                                            allGamesChangePage(page - 1);
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    <img src={arrowleft} alt="arrow left" />
                                </button>
                                <div className="pagination-number">
                                    <h5>{page}</h5>
                                    <span>of {Math.ceil(paginationDataGames?.totalEntries / 15)}{" "}pages</span>
                                </div>
                                <button
                                    className={(page < Math.ceil(paginationDataGames?.totalEntries / 15)) ? "pagination-right enable" : "pagination-right"}
                                    onClick={() => {
                                        if (page < Math.ceil(paginationDataGames?.totalEntries / 15))
                                            allGamesChangePage(page + 1);
                                    }}
                                >
                                    <img src={arrowleft} alt="arrow right" />
                                </button>
                            </div>
                        }
                    </div>
                    {
                        openViewGamesFilter &&
                        <ViewAllGamesFilterModal
                            bookEventCategories={bookEventCategories}
                            setOpenBookEventFilterModal={setOpenViewGamesFilter}
                            toggle={openViewGamesFilter}
                            modalid={'view-all-games-filter'}
                            setGamesDuration={setGamesDuration}
                            setGamesFormat={setGamesFormat}
                            setCatMainTitle={setCatMainTitle}
                            mainTitle={catMainTitle}
                            gamesFormat={gamesFormat}
                            gamesDuration={gamesDuration}
                        />
                    }
                    {
                        openMobileHoverCard &&
                        <PlayCardMobileHoverCard
                            manage={false}
                            // userInfo={userInfo}
                            setOpenShareModal={setOpenShareModal}
                            gameDetail={gameDetails}
                            setOpenMobileHoverCard={setOpenMobileHoverCard}
                            setShareLink={setShareLink}
                        />
                    }
                    {
                        openWebinarDescriptionModal &&
                        <WebinarDescriptionModal
                            modalid={"webinar-description"}
                            setOpenWebinarDescriptionModal={setOpenWebinarDescriptionModal}
                            toggle={openWebinarDescriptionModal}
                            key={"webinar-description"}
                            moreWebinars={getMoreWebinars()}
                            webinar={webinarDetails}
                            // setPlayClicked={setPlayClicked}
                            clickFunction={clickFunction}
                        //   activeCategoryName={activeCategoryName}
                        />
                    }
                </div>
            ) : (
                <div className={loaded ? "manage-games" : "manage-games loading"}>
                    <div className="flex justify-between items-center profile-section">
                        <h5 className="profile-name">Default Games</h5>
                        <div className="flex items-center space-x-2 input-icon">
                            <img src={search} alt="search" />
                            <input
                                type="text"
                                name="search"
                                value={searchText}
                                onChange={handleChange}
                                placeholder="Search a game"
                                className="outline-none"
                            />
                        </div>
                    </div>


                    <LoadingComponent loaded={loaded} >
                        {
                            allGames?.paginationData?.totalEntries > 0 ?
                                <div className="playcard-wrapper">
                                    {
                                        allGames.data.map((game, index) => {
                                            return game.isWebinar ? (
                                                <WebinarPlayCard
                                                    key={JSON.stringify(game)}
                                                    gameDetail={game}
                                                    title={game.title}
                                                    srcImage={[game.mobileCoverMedia]}
                                                    setOpenShareModal={setOpenShareModal}
                                                    setShareLink={setShareLink}
                                                    isFromWebinar={true}
                                                    webinarClickFunction={() => clickFunction(game)}
                                                    setGameDetails={setGameDetails}
                                                    setOpenMobileHoverCard={setOpenMobileHoverCard}
                                                ></WebinarPlayCard>
                                            ) : (
                                                <Playcardlarge
                                                    key={JSON.stringify(game)}
                                                    srcImage={game.coverMedia}
                                                    title={game.title}
                                                    gameDetail={game}
                                                    setOpenShareModal={setOpenShareModal}
                                                    setShareLink={setShareLink}
                                                    setOpenLoginModal={setOpenLoginModal}
                                                    setGameDetails={setGameDetails}
                                                    setOpenMobileHoverCard={setOpenMobileHoverCard}
                                                />

                                            )
                                        })
                                    }
                                </div> :
                                <div className="no-game-wrapper">
                                    <div>
                                        <h3 className="no-game">
                                            The game you are searching for is currently not available.
                                        </h3>
                                        <span className="no-game-span" onClick={() => props.history.push("/")}>
                                            Go to Homepage
                                        </span>
                                    </div>
                                </div>
                        }
                        {allGames?.paginationData?.totalEntries > 0 &&
                            <div className="pagination-wrapper">
                                <button
                                    className={page > 1 ? "pagination-left enable" : "pagination-left"}
                                    onClick={() => {
                                        if (page > 1)
                                            changePage(page - 1);
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    <img src={arrowleft} alt="arrow left" />
                                </button>
                                <div className="pagination-number">
                                    <h5>{page}</h5>
                                    <span>of {Math.ceil(allGames?.paginationData?.totalEntries / 12)}{" "}pages</span>
                                </div>
                                <button
                                    className={(page < Math.ceil(allGames?.paginationData?.totalEntries / 12)) ? "pagination-right enable" : "pagination-right"}
                                    onClick={() => {
                                        if (page < Math.ceil(allGames?.paginationData?.totalEntries / 12))
                                            changePage(page + 1);
                                    }}
                                >
                                    <img src={arrowleft} alt="arrow right" />
                                </button>
                            </div>
                        }
                    </LoadingComponent>
                    {
                        openLoginModal && (
                            <LoginModal
                                modalid="loginmodal"
                                toggle={openLoginModal}
                                setOpenLoginModal={setOpenLoginModal}
                            />
                        )
                    }
                    {
                        openShareModal && (
                            <LikeShareModal
                                toggle={openShareModal}
                                setOpenShareModal={setOpenShareModal}
                                shareLink={shareLink}
                            />
                        )
                    }
                    {
                        openMobileHoverCard &&
                        <PlayCardMobileHoverCard
                            setOpenShareModal={setOpenShareModal}
                            gameDetail={gameDetails}
                            setOpenMobileHoverCard={setOpenMobileHoverCard}
                            setShareLink={setShareLink}
                        />
                    }
                    {
                        openWebinarDescriptionModal &&
                        <WebinarDescriptionModal
                            modalid={"webinar-description"}
                            setOpenWebinarDescriptionModal={setOpenWebinarDescriptionModal}
                            toggle={openWebinarDescriptionModal}
                            key={"webinar-description"}
                            moreWebinars={getMoreWebinars()}
                            webinar={webinarDetails}
                            // setPlayClicked={setPlayClicked}
                            clickFunction={clickFunction}
                        //   activeCategoryName={activeCategoryName}
                        />
                    }

                </div>
            )}
        </div>
    );
}
export default DefaultGames;