import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./playCard.css";
// image
import quesImg from "../../assets/images/questions.svg";
import pauseIcon from "../../assets/images/pauseIcon.svg";
import diyImg from "../../assets/images/diy.svg";
import Watch from "../../assets/images/Watch.png";
import Virtual from "../../assets/images/Virtual.png"
import People from "../../assets/images/People.png"
import Hybrid from "../../assets/images/Hybrid.png"
import Offline from "../../assets/images/offline.png"
import Multilingual from "../../assets/images/multilingual.png"
import playicon from "../../assets/images/playicon.svg";
import { useDispatch, useSelector } from "react-redux";
import { addToFavourite, removeFromFavourite } from "../../redux/actions/homepageActions";
import like from "../../assets/images/like.svg";
import notlike from "../../assets/images/notLike.svg";
import ReactPlayer from "react-player/lazy";
import { BASE_URL, encryptData, S3_BASE_URL } from "../../helpers/helper";
import { OrgRoles } from "../../helpers/userTypes";

const PlayCard = ({
    title,
    srcImage,
    gameDetail,
    setBannerDeleteModal,
    setSloganFlag,
    setGameDetails,
    isAdmin,
    setOpenShareModal,
    setShareLink,
    setHover,
    setHoverSlogan,
    previouslyPlayed,
    setOpenMobileHoverCard,
    componentStyle
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { favGames } = useSelector((state) => state.getFavGames);
    const { userInfo } = useSelector(state => state.getUser);
    const [isHovered, setIsHovered] = useState(false);
    const [play, setPlay] = useState(false);
    const [liked, setLiked] = useState(false);
    const [touchMoved, setTouchMoved] = useState(false);
    const [hovered, setHovered] = useState(false)
    const addFav = () => {
        setLiked(true);
        dispatch(addToFavourite(gameDetail.id));
    };
    const removeFav = () => {
        setLiked(false);
        setTimeout(() => {
            dispatch(removeFromFavourite(gameDetail.id));
        }, 5000);
    };

    useEffect(() => {
        if (favGames && favGames.data) {
            favGames.data.forEach((game) => {
                if (game.id === gameDetail.id) {
                    setLiked(true)
                    return;
                }
            });
        }
    }, [favGames]);

    const handleClick = (e) => {
        if (previouslyPlayed && gameDetail && gameDetail.lastPlayedSessionID) {
            sessionStorage.setItem("gameReportId", encryptData(gameDetail.id));
            history.push("game-reports/" + gameDetail.lastPlayedSessionID);
        }
        else
            history.push("/game-detail/" + gameDetail.id);
    };
    const handleImageClick = (e) => {
        e.preventDefault();
        if (!touchMoved) {
            setGameDetails({ ...gameDetail, previouslyPlayed });
            setOpenMobileHoverCard(true);
            // history.push("/game-detail/" + gameDetail.id);
        }
    };
    const handleShareClick = () => {
        if (userInfo && userInfo.data && OrgRoles.includes(userInfo.data.role)) {
            const { email, organizationId } = userInfo && userInfo.data;
            const obj = { from: email, organizationId: organizationId, onBoardType: "INVITE", redirectURL: "/game-detail/" + gameDetail.id };
            const inviteToken = encryptData(obj);
            const shareLink = BASE_URL + "/join?inviteId=" + inviteToken;
            setShareLink(shareLink);
        }
        else
            setShareLink(BASE_URL + "/game-detail/" + gameDetail.id);
        setOpenShareModal(true);
    };
    const togglePlay = () => {
        setPlay(prevState => !prevState);
    }
    var width;
    const isWideScreen = window.innerWidth > 1000;
    // if (window.innerWidth > 1000) width = window.innerWidth / 6;
    if (window.innerWidth > 600 && window.innerWidth < 1000) width = window.innerHeight / 5;
    return (
        <>
            <div className="playcard hover:scale-[1.2] gameListHover" style={{ width: isWideScreen ? '200px' : width }}
                onTouchStart={() => setTouchMoved(false)}
                onTouchMove={() => setTouchMoved(true)}
                onTouchEnd={handleImageClick}

                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleClick}>

                <div className="absolute gameCardDetails bottom-0 z-10  right-0 left-0 justify-between hidden">
                    {gameDetail?.duration && <div className="font-bold z-10 p-1 bottom-2 flex items-center ">
                        <img fetchpriority="high" className="h-6 w-6" src={Watch} alt="Logo 2" />
                        <span className="font-bold ml-1 text-white">{gameDetail?.duration}:00</span>
                    </div>}
                    {/* {gameDetail?.gameType === "HYBRID" && <img className="absolute font-bold z-10 right-2 bottom-2 pl-[10px] h-[2.2rem]  w-[3.5rem] " src={Hybrid} alt="Logo 3" />}
                    {gameDetail?.gameType === "ONLINE" && <img className="absolute font-bold z-10 right-2 bottom-2" src={Virtual} alt="Logo 3" />}
                    {gameDetail?.gameType === "OFFLINE" && <img className="absolute font-bold z-10 right-2 bottom-2 h-[1.9rem] w-[2rem]" src={Offline} alt="Logo 3" />} */}
                    {gameDetail?.maxPlayers && <div className=" font-bold  p-1 bottom-1 flex items-center hidden">
                        <img fetchpriority="high" className="h-5 w-5" src={People} alt="Logo 3" />
                        <span className="font-bold ml-1 text-xs text-white">{gameDetail?.maxPlayers}+</span>
                    </div>}
                    {/* <img className="absolute font-bold fill-white z-10 right-2 bottom-[70px] mb-[5px]" src={Multilingual} alt="Logo 3" /> */}
                </div>

                <div className={`playcard-img lg:h-[300px] ${componentStyle && componentStyle?.mobile ? componentStyle.mobile : ''} 
                ${componentStyle && componentStyle?.tablet ? componentStyle.tablet : ''} 
                ${componentStyle && componentStyle?.desktop ? componentStyle.desktop : ''} 
                `}>
                    {
                        srcImage && srcImage[0].includes('https://youtu.be') ? (
                            <img fetchpriority="high" src={S3_BASE_URL + srcImage[1]} alt="img" className="relative" />
                        ) : (
                            srcImage && /\.(gif|jpg|jpeg|png)$/.test(srcImage[0]) ? (
                                <img fetchpriority="high" src={S3_BASE_URL + srcImage[0]} alt="img" className="relative" />
                            ) : (
                                <div className="flex w-full rounded-[10px] h-full bg-slate-100 skeleton-animation"></div>
                            )
                        )
                    }
                    <div className=" hidden lg:block rounded-[10px] absolute inset-0
                    "></div>
                </div>

                {/* <h1 className="hidden pt-[5px] ml-1 lg:block absolute text-white uppercase font-semibold z-10">{gameDetail.title}</h1> */}
                {isHovered && (
                    <div className="hidden hidden-description z-10 ml-[-50px] bottom-4 absolute">
                        <p className="block-ellipsis text-[0.60rem] text-white uppercase tracking-tight max-w-[130px] leading-[1] whitespace-pre-wrap">
                            {gameDetail.description}
                        </p>
                    </div>)}
            </div>
        </>
    );
};

export default PlayCard;
