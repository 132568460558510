import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";

import Header from "../../components/header/header";
import PlaycardLarge from "../../components/playCard/playcardlarge";
import Footer from "../../components/footer/footer";

// image
import backbtn from "../../assets/images/arrow-back.svg";
import search from "../../assets/images/search.svg";
import arrowleft from "../../assets/images/paginationarrow.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getContactUs,
  getAllGames,
  getCurrentlyPlayingGames,
  getPreviouslyPlayedGames,
} from "../../redux/actions/homepageActions";
import { logOut } from "../../redux/actions/userAction";
import { gameAllCategory } from "../../redux/actions/gameDetailAction";
import LikeShareModal from "../../components/modal/likesharemodal";
import LoginModal from "../../components/modal/loginmodal";
import PlayCardMobileHoverCard from "../../components/playCard/playCardMobileHoverCard";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { IsAdmin, S3_BASE_URL } from "../../helpers/helper";
import { mapToDropdownArray } from "../../utils";
import Carousel from 'react-multi-carousel';
import { FaAngleDown } from "react-icons/fa";
import PlayCard from "../../components/playCard/playCard_new";
import ViewAllGamesFilterModal from "../../components/modal/ViewAllGamesFilterModal";
import greenStar from "../../assets/icons/green-stars.png"
import iconfilter from "../../assets/icons/iconfilter.png";
import { BiSolidLeftArrow } from "react-icons/bi";


const PlayGame = (props) => {
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [openShareModal, setOpenShareModal] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [games, setGames] = useState();
  const [page, setPage] = useState(1);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openMobileHoverCard, setOpenMobileHoverCard] = useState(false);
  const [gameDetails, setGameDetails] = useState({});

  const ContactUs = useSelector((state) => state.homeContactUs);
  const { contactUs } = ContactUs;
  const SearchResults = useSelector((state) => state.homeSearch);
  const { searchResults } = SearchResults;
  const GameCategory = useSelector((state) => state.gameAllCategory);
  const { gameCategory } = GameCategory;
  const CurrentlyPlayingGames = useSelector(state => state.getCurrentlyPlayingGames);
  const { currentlyPlayingGames } = CurrentlyPlayingGames;
  const PreviouslyPlayedGames = useSelector(state => state.getPreviouslyPlayedGames);
  const { previouslyPlayedGames } = PreviouslyPlayedGames;
  const AllGames = useSelector((state) => state.allGames);
  const { allGames } = AllGames;

  var isAdmin = IsAdmin();

  // const filterGames = (games) => {
  //   let filteredGames = games.filter(game => game.title.toUpperCase().includes(searchText.toUpperCase()));
  //   if (selectedCategory)
  //     filteredGames = filteredGames.filter(game => game.category.filter(gamecategory => gamecategory.id === selectedCategory.id).length > 0)
  //   return filteredGames;
  // }
  useEffect(() => {
    if (props.match.params.id === "Currently Active For Me" && currentlyPlayingGames) {
      setGames(currentlyPlayingGames.data);
    }
    else if (props.match.params.id === "Previously Played By Me" && previouslyPlayedGames) {
      setGames(previouslyPlayedGames.data);
    }
    else if (props.match.params.id === "Search Results..." && searchResults) {
      setGames(searchResults.data);
    }
    else {
      // var gamesOptions = [];
      // if (allGames) {
      //   allGames.data.forEach((game) => {
      //     if (game.slogan) {
      //       game.slogan.forEach(gameSlogan => {
      //         if (gameSlogan.id === props.match.params.id)
      //           gamesOptions.push(game);
      //       })
      //     }
      //   })
      // }
      // setGames(filterGames([...gamesOptions]));
    }
    const page = new URLSearchParams(props.location.search).get('page');
    if (page > 1)
      setPage(parseInt(page));
    else
      setPage(1);
  }, [allGames, currentlyPlayingGames, previouslyPlayedGames, searchResults, selectedCategory, searchText]);
  //to show loader
  useEffect(() => {
    if (
      (ContactUs && ContactUs.loading) ||
      (GameCategory && GameCategory.loading) ||
      (CurrentlyPlayingGames && CurrentlyPlayingGames.loading) ||
      (PreviouslyPlayedGames && PreviouslyPlayedGames.loading) ||
      (AllGames && AllGames.loading) ||
      (SearchResults && SearchResults.loading)
    ) {
      setLoaded(false);
    }
    else
      setLoaded(true);
  }, [ContactUs, GameCategory, CurrentlyPlayingGames, PreviouslyPlayedGames, AllGames, SearchResults])

  useEffect(() => {
    dispatch(gameAllCategory());

    dispatch(getCurrentlyPlayingGames());
    dispatch(getPreviouslyPlayedGames());
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  const signOut = async () => {
    await dispatch(logOut());
    props.history.push("/");
  };




  const [bookEventCategories, setBookEventCategories] = useState([]);
  const [categoryGames, setCategoryGames] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [catMainTitle, setCatMainTitle] = useState('All')
  const [gamesDuration, setGamesDuration] = useState(0);
  const [gamesFormat, setGamesFormat] = useState('');
  const [isDuration, setIsDuration] = useState(false);
  const [isFormat, setIsFormat] = useState(false);
  const [openViewGamesFilter, setOpenViewGamesFilter] = useState(false);
  const [filterCount, setFilterCount] = useState([]);

  const [paginationDataGames, setpaginationDataGames] = useState()

  const [isMobile, setIsMobile] = useState(false);

  const componentStyleValue = {
    mobile: "md:!w-[190px] md:!h-[280px] sm:!w-[155px] sm:!h-[238px] lg:!w-[200px] lg:!h-[300px] !w-[155px] !h-[238px]",
  }


  const handleResize = () => {
    setIsMobile(window.innerWidth <= 464);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    handleResize();
    dispatch(gameAllCategory())
  }, [])


  useEffect(() => {
    if (gameCategory && gameCategory?.data) {
      let filterDataMainTitle = mapToDropdownArray(gameCategory.data, 'header')

      setBookEventCategories(filterDataMainTitle);
    }

  }, [gameCategory, catMainTitle])

  useEffect(() => {

    const callBack = async () => {
      if (games && games.length > 0) {
        // fetchAllGamesData(searchText, catMainTitle, gamesDuration, gamesFormat, page)

        let filterExperienceData = await fetchAllExperienceGames(games)

        let allGames = await removeDuplicateGames(filterExperienceData);

        if (searchText && allGames && allGames.length > 0) {
          allGames = allGames.filter(game =>
            game.title.toLowerCase().includes(searchText.toLowerCase())
          );

          let perPageGames;
          let pageData;
          if (allGames && allGames.length > 0) {
            allGames.sort((a, b) => a.title.localeCompare(b.title));

            const { data, paginationData } = pagination({
              data: allGames,
              perPage: 15,
              currentPage: page,
              getAll: false
            });
            perPageGames = data;
            pageData = paginationData
          }

          if (searchText && searchText !== "") {
            setpaginationDataGames(allGames)
            allGames = allGames
          }
          else {
            setpaginationDataGames(pageData)
            allGames = perPageGames

          }
        }
        if (gamesFormat && allGames && allGames.length > 0) {
          allGames = allGames.filter(game => game.gameType === gamesFormat); // Filter by gameType if format is provided

          let perPageGames;
          let pageData;
          if (allGames && allGames.length > 0) {
            allGames.sort((a, b) => a.title.localeCompare(b.title));

            const { data, paginationData } = pagination({
              data: allGames,
              perPage: 15,
              currentPage: page,
              getAll: false
            });
            perPageGames = data;
            pageData = paginationData
          }

          if (searchText && searchText !== "") {
            setpaginationDataGames(allGames)
            allGames = allGames
          }
          else {
            setpaginationDataGames(pageData)
            allGames = perPageGames

          }
        }
        if (gamesFormat) {
          toggleGamesDurationInFilterCount("gamesFormat")

        }
        else {
          setFilterCount((prevState) => {
            // Check if "gamesDuration" is already in the array
            if (prevState.includes("gamesFormat")) {
              // If present, remove it by filtering it out
              return prevState.filter((item) => item !== "gamesFormat");
            } else {
              // If not present, add it to the array
              return prevState;
            }
          });
        }

        if (searchText && gamesFormat && allGames && allGames.length > 0) {
          allGames = allGames.filter(game =>
            game.title.toLowerCase().includes(searchText.toLowerCase())
          );

          let perPageGames;
          let pageData;
          if (allGames && allGames.length > 0) {
            allGames.sort((a, b) => a.title.localeCompare(b.title));

            const { data, paginationData } = pagination({
              data: allGames,
              perPage: 15,
              currentPage: page,
              getAll: false
            });
            perPageGames = data;
            pageData = paginationData
          }

          if (searchText && searchText !== "") {
            setpaginationDataGames(allGames)
            allGames = allGames
          }
          else {
            setpaginationDataGames(pageData)
            allGames = perPageGames

          }
        }

        if (gamesDuration && allGames && allGames.length > 0) {
          const filteredAndSortedGames = allGames
            .filter((game) => game.duration <= Number(gamesDuration)) // Filter games with duration <= gamesDuration
            .sort((a, b) => (b.duration || 0) - (a.duration || 0)); // Sort by duration in descending order

          allGames = filteredAndSortedGames;

          let perPageGames;
          let pageData;
          if (allGames && allGames.length > 0) {
            allGames.sort((a, b) => a.title.localeCompare(b.title));

            const { data, paginationData } = pagination({
              data: allGames,
              perPage: 15,
              currentPage: page,
              getAll: false
            });
            perPageGames = data;
            pageData = paginationData
          }

          if (searchText && searchText !== "") {
            setpaginationDataGames(allGames)
            allGames = allGames
          }
          else {
            setpaginationDataGames(pageData)
            allGames = perPageGames

          }

        }
        if (gamesDuration) {
          toggleGamesDurationInFilterCount("gamesDuration")

        }
        else {
          setFilterCount((prevState) => {
            // Check if "gamesDuration" is already in the array
            if (prevState.includes("gamesDuration")) {
              // If present, remove it by filtering it out
              return prevState.filter((item) => item !== "gamesDuration");
            } else {
              // If not present, add it to the array
              return prevState;
            }
          });
        }

        setCategoryGames(allGames);

      }
      else if (games && games.length === 0 && searchText === '') {
        let filterExperienceData = await fetchAllExperienceGames(games)

        let uniqueGamesData = await removeDuplicateGames(filterExperienceData);

        let perPageGames;
        let pageData;
        if (uniqueGamesData && uniqueGamesData.length > 0) {
          uniqueGamesData.sort((a, b) => a.title.localeCompare(b.title));

          const { data, paginationData } = pagination({
            data: uniqueGamesData,
            perPage: 15,
            currentPage: page,
            getAll: false
          });
          perPageGames = data;
          pageData = paginationData
        }

        if (searchText && searchText !== "") {
          setpaginationDataGames(uniqueGamesData)
          setCategoryGames(uniqueGamesData)
        }
        else {
          setpaginationDataGames(pageData)
          setCategoryGames(perPageGames)

        }

      }
    }
    callBack();

  }, [page, searchText, gamesDuration, gamesFormat]);


  useEffect(() => {
    const callBack = async () => {
      if (!Array.isArray(catMainTitle) && catMainTitle && catMainTitle !== "All") {
        toggleGamesDurationInFilterCount("catMainTitle")
      }
      else if (Array.isArray(catMainTitle) && catMainTitle.length > 0 && catMainTitle[0].title === "All" || Array.isArray(catMainTitle) && catMainTitle.length > 0) {

        toggleGamesDurationInFilterCount("catMainTitle")

      }
      else {
        setFilterCount((prevState) => {
          // Check if "gamesDuration" is already in the array
          if (prevState.includes("catMainTitle")) {
            // If present, remove it by filtering it out
            return prevState.filter((item) => item !== "catMainTitle");
          } else {
            // If not present, add it to the array
            return prevState;
          }
        });
      }

      if (games && games.length > 0) {

        let filterExperienceData = await fetchAllExperienceGames(games)
        // let allExperienceTypes = fetchAllExperiencesTypes(games)

        // setBookEventCategories(allExperienceTypes);

        let uniqueGamesData = await removeDuplicateGames(filterExperienceData);

        let perPageGames;
        let pageData;
        if (uniqueGamesData && uniqueGamesData.length > 0) {
          uniqueGamesData.sort((a, b) => a.title.localeCompare(b.title));

          const { data, paginationData } = pagination({
            data: uniqueGamesData,
            perPage: 15,
            currentPage: page,
            getAll: false
          });
          perPageGames = data;
          pageData = paginationData
        }

        if (searchText && searchText !== "") {
          setpaginationDataGames(uniqueGamesData)
          setCategoryGames(uniqueGamesData)
        }
        else {
          setpaginationDataGames(pageData)
          setCategoryGames(perPageGames)

        }

      }
    }
    callBack()

  }, [previouslyPlayedGames, catMainTitle, games])


  const fetchAllExperienceGames = (gameData) => {
    setLoaded(false)
    const categoryWiseDataMap = new Map();

    gameData.forEach(game => {
      game.category.forEach(cat => {
        const mainTitle = cat.mainTitle;

        if (!categoryWiseDataMap.has(mainTitle)) {
          categoryWiseDataMap.set(mainTitle, {
            mainTitle: mainTitle,
            svgPath: cat.svgPath,
            thumbnail: cat.thumbnail,
            experienceType: cat.experienceType,
            mainTitleIndex: cat.mainTitleIndex,
            games: []
          });
        }

        categoryWiseDataMap.get(mainTitle).games.push(game);
      });
    });

    if (Array.isArray(catMainTitle) && catMainTitle.length > 0 && catMainTitle[0].title !== "All") {
      const filteredTitles = catMainTitle.map((data) => data.title);
      const filteredData = Array.from(categoryWiseDataMap.values()).filter((category) =>
        filteredTitles.includes(category.mainTitle)
      );
      setLoaded(true);
      return filteredData;
    }

    if (!Array.isArray(catMainTitle) && catMainTitle && catMainTitle !== "All") {
      setLoaded(true)
      return categoryWiseDataMap.has(catMainTitle)
        ? [categoryWiseDataMap.get(catMainTitle)]
        : [];
    }

    setLoaded(true)
    return Array.from(categoryWiseDataMap.values());

  }


  function removeDuplicateGames(data) {
    const allGames = data.flatMap(category => category.games);

    // Remove duplicates based on the 'id' field
    const uniqueGames = allGames.reduce((acc, game) => {
      if (!acc.some(g => g.id === game.id)) {
        acc.push(game);
      }
      return acc;
    }, []);

    // Sort the games alphabetically by 'title'
    uniqueGames.sort((a, b) => a.title.localeCompare(b.title));

    // let perPageGames;
    // let pageData;
    // if (uniqueGames && uniqueGames.length > 0) {
    //   uniqueGames.sort((a, b) => a.title.localeCompare(b.title));

    //   const { data, paginationData } = pagination({
    //     data: uniqueGames,
    //     perPage: 15,
    //     currentPage: page,
    //     getAll: false
    //   });
    //   perPageGames = data;
    //   pageData = paginationData
    // }

    // if (searchText && searchText !== "") {
    //   setpaginationDataGames(uniqueGames)
    // }
    // else {
    //   setpaginationDataGames(pageData)
    // }

    // if (searchText && searchText !== "") {
    return uniqueGames
    // }
    // else {
    //   return perPageGames

    // }

  }

  const allGamesChangePage = (newPage) => {
    setLoaded(false);
    setPage(newPage)
    setTimeout(() => {
      setLoaded(true)
    }, 100);
  }


  const handleExperienceClick = (e, title) => {
    e.preventDefault();
    setCatMainTitle(title)
  }


  function CardImage({ src, classValues, indx }) {
    const [imageLoaded, setImageLoaded] = useState(false);

    return (

      <img
        src={`${S3_BASE_URL + src}`}
        alt={`Card ${indx} Image`}
        className={`${classValues} ${imageLoaded ? 'opacity-100' : ' bg-slate-100 skeleton-animation'}`}
        onLoad={() => setImageLoaded(true)}
      />

    );
  }

  const handleDurationFilterCheckboxChange = (e, filterValue) => {
    e.stopPropagation();
    // e.preventDefault();

    if (e.target.checked) {
      if (gamesDuration === filterValue) {
        e.preventDefault();
        setGamesDuration(0);

      }
    } else {
      setGamesDuration(filterValue);
    }
  };

  const handleSearchDuration = async (e, filterValue) => {
    if (gamesDuration === filterValue) {
      e.preventDefault();
      setGamesDuration(0);

    }
    else {
      setGamesDuration(filterValue);
    }
  }

  const handleFormatFilterCheckboxChange = (e, filterValue) => {
    e.stopPropagation();
    // e.preventDefault();

    if (e.target.checked) {
      if (gamesFormat === filterValue) {
        e.preventDefault();
        setGamesFormat('');

      }
    } else {
      setGamesFormat(filterValue);
    }
  };

  const handleSearchFormat = async (e, filterValue) => {
    if (gamesFormat === filterValue) {
      e.preventDefault();
      setGamesFormat('');

    }
    else {
      setGamesFormat(filterValue);
    }
  }

  const durationClick = () => {
    setIsDuration(!isDuration)

  }
  const formatClick = () => {

    setIsFormat(!isFormat)

  }
  const dropdownRef = useRef(null);
  const formatDropdownRef = useRef(null)

  // // Function to handle clicks outside of the dropdown
  // const handleClickOutside = (e) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
  //     setIsDuration(false);
  //   }
  //   else if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
  //     durationClick()
  //   }
  // };

  // const handleClickFormatOutside = (e) => {
  //   if (formatDropdownRef.current && !formatDropdownRef.current.contains(e.target)) {
  //     setIsFormat(false);
  //   }
  //   else if (formatDropdownRef.current && formatDropdownRef.current.contains(e.target)) {
  //     formatClick();
  //   }
  // };
  const dropdownRefMob = useRef(null);
  const formatDropdownRefMob = useRef(null)

  // Function to handle clicks outside of the dropdown
  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDuration(false);
    }
    else if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
      durationClick()
    }

    if (
      dropdownRefMob.current &&
      !dropdownRefMob.current.contains(e.target) && isMobile
    ) {
      setIsDuration(false);

    }
  };

  const handleClickFormatOutside = (e) => {


    if (!isMobile && formatDropdownRef.current && !formatDropdownRef.current.contains(e.target)) {
      setIsFormat(false);

    }
    else if (formatDropdownRef.current && formatDropdownRef.current.contains(e.target)) {
      formatClick();
    }

    if (
      formatDropdownRefMob.current &&
      !formatDropdownRefMob.current.contains(e.target) && isMobile
    ) {
      setIsFormat(false);

    }
  };

  // Add event listener when component mounts and remove it on unmount
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {

    document.addEventListener('mousedown', handleClickFormatOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickFormatOutside);
    };
  }, []);

  const handleFilterClick = () => {
    setOpenViewGamesFilter(true)
  }

  const toggleGamesDurationInFilterCount = (ele) => {
    setFilterCount((prevState) => {
      // Check if "gamesDuration" is already in the array
      if (prevState.includes(ele)) {
        // If present, remove it by filtering it out
        return prevState;
      } else {
        // If not present, add it to the array
        return [...prevState, ele];
      }
    });
  };

  const handleDurationFilterCheckboxChangeMobile = (e, filterValue) => {
    e.stopPropagation();
    // e.preventDefault();

    if (gamesDuration === filterValue) {
      e.preventDefault();
      setGamesDuration(0);

    }
    else {
      setGamesDuration(filterValue);
    }
  };

  const handleFormatFilterCheckboxChangeMobile = (e, filterValue) => {
    e.stopPropagation();
    // e.preventDefault();

    if (gamesFormat === filterValue) {
      e.preventDefault();
      setGamesFormat('');

    }
    else {
      setGamesFormat(filterValue);
    }
  };
  const pagination = ({ data, perPage, currentPage, getAll = false }) => {
    try {
      perPage = Number(perPage);
      currentPage = Number(currentPage);
      if (getAll) {
        return {
          data,
          paginationData: {
            currentPage: 1,
            perPage: data.length,
            totalEntries: data.length
          }
        };
      }
      let tableDataCount = data.length;
      let paginationItemsCount =
        tableDataCount % perPage === 0
          ? tableDataCount / perPage
          : tableDataCount / perPage + 1;
      paginationItemsCount = Math.floor(paginationItemsCount);
      let currPage = currentPage > paginationItemsCount ? 1 : currentPage;
      return {
        data: data.slice((currPage - 1) * perPage, currPage * perPage),
        paginationData: {
          currentPage: currPage,
          perPage: perPage,
          totalEntries: data.length
        }
      };
    } catch (error) {
      console.log(`"ERROR => ${error}"`);
      throw { error };
    }
  };

  return (
    <div>
      <Header {...props} profile signOut={signOut} />
      <div className='z-[80] pr-[0px] md:pr-[10px] lg:pr-[110px] pl-[0px] md:pl-[10px] lg:pl-[110px] h-fit min-h-[100vh] max-h-fit'>

        <div className="top-[-300px] right-[100px] z-0 fixed md:flex lg:flex hidden w-[870px] h-[860px]"
          style={{
            background: 'radial-gradient(circle, rgb(245, 232, 227, 0.8) -29%, rgb(253, 248, 235, 0.5) 28%, rgb(255, 255, 255, 0) 72%)'
          }}
        ></div>

        <div className="top-[330px] left-[-200px] z-0 fixed md:flex lg:flex hidden w-[470px] h-[460px]"
          style={{
            background: 'radial-gradient(circle, rgb(282, 194, 217) -43%, rgb(279, 232, 235) 19%, rgb(255, 255, 255) 69%)'
          }}
        ></div>

        <div className="mt-[92px] pl-2 w-full">
          <div className="flex items-center w-[65px] cursor-pointer"
            onClick={() => {
              props.history.push("/");
            }}
          >
            <BiSolidLeftArrow className='w-[18px] h-[18px] text-black' />
            <span className='ml-1 font-sans text-[18px] text-black'>Back</span>
          </div>
        </div>
        <section className={`section ${isMobile ? 'mt-[45px]' : 'mt-[70px]'} ${isMobile ? 'mb-[45px]' : `mb-[100px]`} mx-auto max-w-[100%] z-[80]`}>
          {!isMobile && (
            <Carousel
              autoPlay={false}
              additionalTransfrom={0}
              arrows={true}
              autoPlaySpeed={3000}
              centerMode={false}
              className={` relative ${bookEventCategories.length < 4 ? 'flex justify-center' : ''}`} // Add conditional classes
              containerClass="container-with-dots"
              draggable
              // infinite
              itemClass="carousel-item"
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              responsive={{
                desktop: {
                  breakpoint: { max: 3000, min: 1400 },
                  items: 6,
                  partialVisibilityGutter: 40,
                },
                semi_medium: {
                  breakpoint: { max: 1400, min: 900 },
                  items: 4,
                  partialVisibilityGutter: 30,
                },
                medium: {
                  breakpoint: { max: 900, min: 464 },
                  items: 3.5,
                  partialVisibilityGutter: 30,
                },
              }}
              slidesToSlide={1}
              swipeable
            >
              <div key={0} className="flex flex-col justify-center items-center mx-2 cursor-pointer"
                onClick={(e) => handleExperienceClick(e, 'All')}

              >
                <div className="flex justify-center items-center border-[3px] border-gray-400 bg-gray-100 rounded-full w-[100px] md:w-[150px] h-[100px] md:h-[150px]">

                  <h1 className='font-bold text-[18px] text-gray-500 md:text-[32px] lg:text-[40px]'>All</h1>
                </div>
                <h5
                  className={`mt-3 text-[16px] text-center font-bold capitalize ${catMainTitle === 'All' ? 'underline underline-offset-2' : ''}`}
                >All</h5>
              </div>
              {bookEventCategories && bookEventCategories.length > 0 ? bookEventCategories.map((logo, index) => (
                <div key={index} className="flex flex-col justify-center items-center mx-2 cursor-pointer"
                  onClick={(e) => handleExperienceClick(e, logo.mainTitle)}
                >
                  {
                    logo && logo?.thumbnail && /\.(gif|jpg|jpeg|png)$/.test(logo.thumbnail) ? (

                      <CardImage src={logo.thumbnail} classValues={"md:w-[150px] md:h-[150px] w-[100px] h-[100px] rounded-full object-cover"} indx={index} />
                    ) : (
                      <div className="bg-slate-100 rounded-full w-[77px] md:w-[150px] h-[77px] md:h-[150px] skeleton-animation"></div>

                    )
                  }

                  <h5
                    className={`mt-3 text-[16px] text-center font-bold capitalize ${catMainTitle === logo.mainTitle ? 'underline underline-offset-2' : ''}`}
                  >{logo.mainTitle === "DEIB - Diversity, Inclusion, Equity and Belonging" ? "DEIB" : logo.mainTitle}</h5>
                </div>
              ))
                : Array.from({ length: 6 }).map((_, index) => (
                  <div key={index} className="flex flex-col justify-center items-center mx-2 cursor-pointer">
                    <div className="bg-slate-100 rounded-full w-[77px] md:w-[150px] h-[77px] md:h-[150px] skeleton-animation"></div>


                    <h5
                      className={`mt-3 w-[103px] h-[28px] bg-slate-100 skeleton-animation"`}
                    ></h5>
                  </div>
                ))
              }
            </Carousel>
          )}

          {isMobile && (
            <Carousel
              autoPlay={false}
              additionalTransfrom={0}
              arrows={false}
              autoPlaySpeed={3000}
              centerMode={false}
              className={` relative ${bookEventCategories.length < 4 ? 'flex justify-center' : ''}`} // Adjust for mobile
              containerClass="container-with-dots"
              draggable
              // infinite
              itemClass="carousel-item"
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              responsive={{
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 3.1,
                  partialVisibilityGutter: 30,
                },
              }}
              slidesToSlide={1}
              swipeable
            >
              <div key={0} className="flex flex-col justify-center items-center mx-2 cursor-pointer"
                onClick={(e) => handleExperienceClick(e, 'All')}

              >
                <div className="flex justify-center items-center border-[3px] border-gray-400 bg-gray-100 rounded-full w-[77px] md:w-[150px] h-[77px] md:h-[150px]">

                  <h1 className='font-bold text-[18px] text-gray-500 md:text-[32px] lg:text-[40px]'>All</h1>
                </div>
                <h5
                  className={`mt-3 text-[14px] text-center font-bold capitalize ${catMainTitle === "All" ? 'underline underline-offset-2' : ''}`}
                >All</h5>
              </div>
              {bookEventCategories && bookEventCategories.length > 0 ? bookEventCategories.map((logo, index) => (
                <div key={index} className="flex flex-col justify-center items-center mx-2 cursor-pointer"
                  onClick={(e) => handleExperienceClick(e, logo.mainTitle)}

                >

                  {
                    logo && logo?.thumbnail && /\.(gif|jpg|jpeg|png)$/.test(logo.thumbnail) ? (

                      <CardImage src={logo.thumbnail} classValues={"md:w-[150px] md:h-[150px]  w-[77px] h-[77px] rounded-full object-cover"} indx={index} />

                    ) : (
                      <div className="bg-slate-100 rounded-full w-[77px] md:w-[150px] h-[77px] md:h-[150px] skeleton-animation"></div>

                    )
                  }
                  <h5
                    className={`mt-3 text-[14px] text-center font-bold capitalize ${catMainTitle === logo.mainTitle ? 'underline underline-offset-2' : ''}`}
                  >{logo.mainTitle === "DEIB - Diversity, Inclusion, Equity and Belonging" ? "DEIB" : logo.mainTitle}</h5>
                </div>
              ))
                : Array.from({ length: 6 }).map((_, index) => (
                  <div key={index} className="flex flex-col justify-center items-center mx-2 cursor-pointer">
                    <div className="bg-slate-100 rounded-full w-[77px] md:w-[150px] h-[77px] md:h-[150px] skeleton-animation"></div>


                    <h5
                      className={`mt-3 w-[70px] h-[25px] bg-slate-100 skeleton-animation"`}
                    ></h5>
                  </div>
                ))
              }
            </Carousel>
          )}
        </section>

        <div className="flex flex-row justify-around items-center lg:hidden pb-[10px] w-full">
          <div className="relative z-[80] flex flex-row justify-center items-center lg:hidden bg-[#FDF1F3] rounded-[6px] w-[88px] h-[38px] cursor-pointer"
            onClick={handleFilterClick}
            style={{
              boxShadow: '0px 3px 6px #00000029'
            }}
          >
            <img
              src={iconfilter}
              alt="Filter Icon"
              className="mr-2 w-[15px] h-[15px]"
            />
            <span className='font-semibold text-[14px]'>Filter</span>
            {
              filterCount && filterCount.length > 0 && (
                <div className="top-[-8px] right-[-10px] absolute flex justify-center items-center bg-sky-400 rounded-full w-[25px] h-[25px] text-white">{filterCount.length}</div>

              )
            }

          </div>



          <div className="relative z-[80] flex justify-center items-center bg-[#FDF1F3] rounded-[6px] w-[88px] h-[38px] cursor-pointer"
            style={{
              boxShadow: '0px 3px 6px #00000029'
            }}
            ref={isMobile ? formatDropdownRefMob : null}
            onClick={formatClick}
          >
            <span className='font-semibold text-[14px]'>Format</span>
            <FaAngleDown className='ml-[8px] w-[15px] h-[15px]' />

            {
              isFormat && (
                <div className="bottom-[-105px] z-[200] absolute flex flex-col justify-center bg-white rounded-[6px] w-full h-auto"
                  style={{
                    boxShadow: '0px 3px 6px #00000029'
                  }}
                >
                  {
                    ["ONLINE", "OFFLINE", "HYBRID"].map((val, index) => (
                      <div className="flex items-center gap-[8px] hover:bg-gray-200 pt-2 pb-2 pl-3 w-full cursor-pointer"
                        onClick={(e) => handleFormatFilterCheckboxChangeMobile(e, val)}
                        style={{
                          background: gamesFormat === val ? 'rgb(223 178 244)' : ''
                        }}
                      >
                        {/* <div >
                                                        <label class="checkbox-label">
                                                            <input type="checkbox" class={`${gamesFormat} checkbox-tow`}
                                                                checked={gamesFormat === val ? true : false}
                                                                onChange={(e) => handleFormatFilterCheckboxChange(e, val)}
                                                            />
                                                            <div class="svg-icon">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    height="16px"
                                                                    viewBox="0 0 448 512"
                                                                >
                                                                    <path
                                                                        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                            <span class="check-box-container"></span>
                                                        </label>
                                                    </div> */}

                        {/* </label> */}
                        <div className={` pl-2 text-[14px] ${gamesFormat === val ? `font-bold` : `font-normal`} text-center  text-gray750 heading `} id='duration-text-cont' style={{ fontSize: gamesFormat === val ? '0.930rem' : '0.875rem' }}>
                          {val === "ONLINE" ? "Virtual" : val === "OFFLINE" ? "Onsite" : "Hybird"}
                        </div>
                      </div>
                    ))
                  }
                </div>
              )
            }
          </div>

          <div className="relative z-[200] flex justify-center items-center bg-[#FDF1F3] rounded-[6px] w-[88px] h-[38px] cursor-pointer"
            onClick={durationClick}
            ref={isMobile ? dropdownRefMob : null}
            style={{
              boxShadow: '0px 3px 6px #00000029'
            }}
          >
            <span className='font-semibold text-[14px]'>Duration</span>
            <FaAngleDown className='ml-[8px] w-[15px] h-[15px]' />
            {
              isDuration && (
                <div className="bottom-[-230px] z-50 absolute flex flex-col justify-center bg-white rounded-[6px] w-full h-auto"
                  style={{
                    boxShadow: '0px 3px 6px #00000029'
                  }}

                >
                  {
                    [5, 10, 20, 30, 40, 50, 60].map((val, index) => (
                      <div className="flex items-center gap-[8px] hover:bg-gray-200 pt-2 pb-2 pl-3 w-full cursor-pointer"
                        onClick={(e) => handleDurationFilterCheckboxChangeMobile(e, val)}
                        style={{
                          background: gamesDuration == val ? 'rgb(223 178 244)' : ''
                        }}
                      >
                        {/* <div >
                                                        <label class="checkbox-label">
                                                            <input type="checkbox" class={`${gamesDuration} checkbox-tow`}
                                                                checked={gamesDuration == val ? true : false}
                                                                onChange={(e) => handleDurationFilterCheckboxChange(e, val)}
                                                            />
                                                            <div class="svg-icon">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    height="16px"
                                                                    viewBox="0 0 448 512"
                                                                >
                                                                    <path
                                                                        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                            <span class="check-box-container"></span>
                                                        </label>
                                                    </div> */}

                        {/* </label> */}
                        <div className={` pl-2 text-[14px] ${gamesDuration === val ? `font-bold` : `font-normal`} text-center  text-gray750 heading `} id='duration-text-cont' style={{ fontSize: gamesDuration === val ? '0.930rem' : '0.875rem' }}>
                          {val} min
                        </div>
                      </div>
                    ))
                  }
                </div>
              )
            }

          </div>
        </div>
        <div className="z-0 flex lg:hidden ml-[10px] pb-[35px] w-full">
          <div className="z-[0] flex items-center mt-3 md:mt-0 lg:mt-0 !w-full md:!w-[310px] lg:!w-[430px] h-[47px]">
            <div className="z-[0] !w-full md:!w-[310px] lg:!w-[430px] input-icon">
              <img src={search} alt="search" />
              <input type='text' name='search' value={searchText} onChange={handleChange} placeholder="Search a game" className='z-[80]' />
            </div>
          </div>
        </div>

        <div className="lg:flex md:flex-row lg:flex-row flex-col justify-between items-center hidden ml-[10px] pb-[35px] w-full">
          <div className="relative z-[80] lg:flex flex-row justify-center items-center hidden bg-[#FDF1F3] rounded-[6px] w-[140px] h-[47px] cursor-pointer"
            onClick={handleFilterClick}
            style={{
              boxShadow: '0px 3px 6px #00000029'
            }}
          >
            <img
              src={iconfilter}
              alt="Filter Icon"
              className="mr-2"
            />
            <span className='font-semibold text-[16px]'>Filter</span>
            {
              filterCount && filterCount.length > 0 && (
                <div className="top-[-8px] right-[-10px] absolute flex justify-center items-center bg-sky-400 rounded-full w-[25px] h-[25px] text-white">{filterCount.length}</div>

              )
            }

          </div>

          <div className="z-[80] flex items-center mt-3 md:mt-0 lg:mt-0 !w-full md:!w-[310px] lg:!w-[430px] h-[47px]">
            <div className="z-[80] !w-full md:!w-[310px] lg:!w-[430px] input-icon">
              <img src={search} alt="search" />
              <input type='text' name='search' value={searchText} onChange={handleChange} placeholder="Search a game" className='z-[80]' />
            </div>
          </div>

          <div className="lg:flex items-center hidden">

            <div className="relative z-[80] flex justify-center items-center bg-[#FDF1F3] rounded-[6px] w-[140px] h-[47px] cursor-pointer"
              style={{
                boxShadow: '0px 3px 6px #00000029'
              }}
              ref={!isMobile ? formatDropdownRef : null}
              // onClick={formatClick}
            >
              <span className='font-semibold text-[16px]'>Format</span>
              <FaAngleDown className='ml-[8px] w-[15px] h-[15px]' />

              {
                isFormat && (
                  <div className="bottom-[-145px] z-50 absolute flex flex-col justify-center bg-white rounded-[6px] w-full h-auto"
                    style={{
                      boxShadow: '0px 3px 6px #00000029'
                    }}
                  >
                    {
                      ["ONLINE", "OFFLINE", "HYBRID"].map((val, index) => (
                        <div className="flex items-center gap-[8px] hover:bg-gray-200 pt-2 pb-2 pl-3 w-full cursor-pointer"
                          onClick={(e) => handleSearchFormat(e, val)}
                          style={{
                            background: gamesFormat === val ? 'rgb(223 178 244)' : ''
                          }}
                        >
                          <div >
                            <label class="checkbox-label">
                              <input type="checkbox" class={`${gamesFormat} checkbox-tow`}
                                checked={gamesFormat === val ? true : false}
                                onChange={(e) => handleFormatFilterCheckboxChange(e, val)}
                              />
                              <div class="svg-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="16px"
                                  viewBox="0 0 448 512"
                                >
                                  <path
                                    d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                  ></path>
                                </svg>
                              </div>
                              <span class="check-box-container"></span>
                            </label>
                          </div>

                          {/* </label> */}
                          <div className={` pl-2 text-sm ${gamesFormat === val ? `font-bold` : `font-normal`} text-center  text-gray750 heading `} id='duration-text-cont' style={{ fontSize: gamesFormat === val ? '0.930rem' : '0.875rem' }}>
                            {val === "ONLINE" ? "Virtual" : val === "OFFLINE" ? "Onsite" : "Hybird"}
                          </div>
                        </div>
                      ))
                    }
                  </div>
                )
              }
            </div>

            <div className="relative z-[80] flex justify-center items-center bg-[#FDF1F3] ml-3 rounded-[6px] w-[140px] h-[47px] cursor-pointer"
              // onClick={durationClick}
              ref={!isMobile ? dropdownRef : null}
              style={{
                boxShadow: '0px 3px 6px #00000029'
              }}
            >
              <span className='font-semibold text-[16px]'>Duration</span>
              <FaAngleDown className='ml-[8px] w-[15px] h-[15px]' />
              {
                isDuration && (
                  <div className="bottom-[-330px] z-50 absolute flex flex-col justify-center bg-white rounded-[6px] w-full h-auto"
                    style={{
                      boxShadow: '0px 3px 6px #00000029'
                    }}
                  >
                    {
                      [5, 10, 20, 30, 40, 50, 60].map((val, index) => (
                        <div className="flex items-center gap-[8px] hover:bg-gray-200 pt-2 pb-2 pl-3 w-full cursor-pointer"
                          onClick={(e) => handleSearchDuration(e, val)}
                          style={{
                            background: gamesDuration == val ? 'rgb(223 178 244)' : ''
                          }}
                        >
                          <div >
                            <label class="checkbox-label">
                              <input type="checkbox" class={`${gamesDuration} checkbox-tow`}
                                checked={gamesDuration == val ? true : false}
                                onChange={(e) => handleDurationFilterCheckboxChange(e, val)}
                              />
                              <div class="svg-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="16px"
                                  viewBox="0 0 448 512"
                                >
                                  <path
                                    d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                  ></path>
                                </svg>
                              </div>
                              <span class="check-box-container"></span>
                            </label>
                          </div>

                          {/* </label> */}
                          <div className={` pl-2 text-sm ${gamesDuration === val ? `font-bold` : `font-normal`} text-center  text-gray750 heading `} id='duration-text-cont' style={{ fontSize: gamesDuration === val ? '0.930rem' : '0.875rem' }}>
                            {val} min
                          </div>
                        </div>
                      ))
                    }
                  </div>
                )
              }


            </div>

          </div>
        </div>

        <div className="gap-4 gap-y-8 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5" >
          {
            loaded && loaded === true && categoryGames && categoryGames.length > 0 ? categoryGames.map((game, index) => {
              return (

                <PlayCard
                  key={JSON.stringify(game)}
                  gameDetail={game}
                  title={game.title}
                  srcImage={game.coverMedia}
                  setOpenShareModal={setOpenShareModal}
                  setShareLink={setShareLink}
                  // setHover={setHover}
                  // setHoverSlogan={() => setHoverSlogan(slogan.id)}
                  setGameDetails={setGameDetails}
                  setOpenMobileHoverCard={setOpenMobileHoverCard}
                  componentStyle={componentStyleValue}
                />

              )
            })
              : !loaded ?
                Array.from({ length: 5 }).map((_, indx) => (
                  <div className="z-[80] flex justify-center items-center mr-[20px] mb-5 rounded-[10px] md:w-[220px] lg:w-[220px] md:h-[320px] lg:h-[320px]">
                    <div className="flex bg-slate-100 rounded-[10px] w-[200px] h-[300px] skeleton-animation">

                    </div>
                  </div>
                ))
                // : (
                //   <div className="no-game-wrapper">
                //     <div>
                //       <h3 className="no-game">
                //         {searchText.length === 0
                //           ? "Currently, there are no games under this Experiences."
                //           : "Currently, there are no games with this name under this Experiences."}
                //       </h3>
                //       <span
                //         className="no-game-span"
                //         onClick={() => props.history.push("/")}
                //       >
                //         Go to Homepage
                //       </span>
                //     </div>
                //   </div>
                // )
                : null
          }
        </div>
        {
          loaded && categoryGames && categoryGames.length === 0 || loaded && !categoryGames ? (
            <div className="flex justify-center items-center w-full h-auto">
              <div className="no-game-wrapper">
                <div>
                  <h3 className="no-game">
                    {searchText.length === 0
                      ? "Currently, there are no games under this Experiences."
                      : "Currently, there are no games with this name under this Experiences."}
                  </h3>
                  <span
                    className="no-game-span"
                    onClick={() => props.history.push("/")}
                  >
                    Go to Homepage
                  </span>
                </div>
              </div>
            </div>
          )
            : null
        }

        <div className="flex justify-center items-center mb-8 w-full">
          {paginationDataGames?.totalEntries > 0 &&
            <div className="pagination-wrapper">
              <button
                className={page > 1 ? "pagination-left enable" : "pagination-left"}
                onClick={() => {
                  if (page > 1)
                    allGamesChangePage(page - 1);
                  window.scrollTo(0, 0);
                }}
              >
                <img src={arrowleft} alt="arrow left" />
              </button>
              <div className="pagination-number">
                <h5>{page}</h5>
                <span>of {Math.ceil(paginationDataGames?.totalEntries / 15)}{" "}pages</span>
              </div>
              <button
                className={(page < Math.ceil(paginationDataGames?.totalEntries / 15)) ? "pagination-right enable" : "pagination-right"}
                onClick={() => {
                  if (page < Math.ceil(paginationDataGames?.totalEntries / 15))
                    allGamesChangePage(page + 1);
                }}
              >
                <img src={arrowleft} alt="arrow right" />
              </button>
            </div>
          }
        </div>
        {
          openViewGamesFilter &&
          <ViewAllGamesFilterModal
            bookEventCategories={bookEventCategories}
            setOpenBookEventFilterModal={setOpenViewGamesFilter}
            toggle={openViewGamesFilter}
            modalid={'view-all-games-filter'}
            setGamesDuration={setGamesDuration}
            setGamesFormat={setGamesFormat}
            setCatMainTitle={setCatMainTitle}
            mainTitle={catMainTitle}
            gamesFormat={gamesFormat}
            gamesDuration={gamesDuration}
          />
        }
        {
          openMobileHoverCard &&
          <PlayCardMobileHoverCard
            manage={false}
            // userInfo={userInfo}
            setOpenShareModal={setOpenShareModal}
            gameDetail={gameDetails}
            setOpenMobileHoverCard={setOpenMobileHoverCard}
            setShareLink={setShareLink}
          />
        }
      </div>
      {
        openMobileHoverCard &&
        <PlayCardMobileHoverCard
          setOpenShareModal={setOpenShareModal}
          gameDetail={gameDetails}
          setOpenMobileHoverCard={setOpenMobileHoverCard}
          setShareLink={setShareLink}
        />
      }
      {contactUs ? <Footer {...props} isAdmin={isAdmin} contactUs={contactUs.data} /> : ""}
    </div>
  );
};

export default PlayGame;
