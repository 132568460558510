import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

// Container for the tabs
const StyledTabsContainer = styled.div`
  position: relative;
  list-style: none;
  display: flex;
  align-items: center;
  border-radius: 6px; /* Rounded corners for the tabs container */
  overflow: hidden; /* Hide overflow from the sliding background */
  padding: 6px; /* Padding to create space around the tabs */
  
  /* Flex layout for large screens */
  @media (min-width: 601px) {
    justify-content: flex-start; /* Distribute tabs with space between them */
    overflow: hidden; /* Hide any overflow */
  }
  
  /* Media query for small screens */
  @media (max-width: 600px) {
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent line breaks */
    padding: 6px 0; /* Adjust padding for small screens */
    
    /* Hide scrollbar for better appearance */
    &::-webkit-scrollbar {
      display: none; /* For WebKit browsers (Chrome, Safari) */
    }
  }
`;

// Background that slides beneath the buttons
const SlidingBackground = styled.div`
  position: absolute;
  top: ${(props) => props.top}; /* Position the sliding background */
  left: ${(props) => props.left}; /* Position the sliding background */
  height: ${(props) => props.height}; /* Dynamic height based on active tab */
  width: ${(props) => props.width}; /* Dynamic width based on active tab */

  background-color: var(--color-theme); /* Background color for the sliding effect */
  transition: top 0.3s ease, left 0.3s ease, height 0.3s ease, width 0.3s ease; /* Smooth sliding and resizing transition */
  z-index: 1; /* Ensure it is below the text but above the container */
  border-radius: 6px; /* Match rounded corners of the container */
`;

// Styled component for each tab
const StyledTab = styled.li`
  display: inline-block; /* Ensure tabs are displayed in a line for scrolling */
  position: relative;
  margin: 0 4px; /* Space between tabs */
  height: fit-content; /* Height based on content */
  min-width: 120px; /* Ensure minimum width for each tab */

  /* Border radius to match active background */
  border-radius: 6px; /* Match rounded corners of the sliding background */

  button {
    cursor: pointer;
    position: relative;
    z-index: 2; /* Ensure text is above the sliding background */
    transition: color 0.3s, background-color 0.3s, border 0.3s; /* Smooth transitions */
    color: ${(props) => (props.isFocused ? "#fff" : "#000")}; /* Text color for focused and non-focused tabs */
    background-color: ${(props) => (props.isFocused ? "transparent" : "transparent")}; /* Background color for non-active tabs */
    
    width: 100%;
    height: 100%;
    padding: 10px; /* Padding inside each tab */
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
    text-align: center; /* Center text */
    border-radius: 6px; /* Rounded corners */
    background-color: ${(props) => (props.isFocused ? "transparent" : "transparent")}; /* Transparent background for non-active tabs */
   
    /* Add box shadow to each tab */
    box-shadow: ${(props) =>
      props.isFocused ? "none" : "#e1e6ea 0px 0px 8px"};
  }
`;

const Tabs = ({ value, onChange, tabs }) => {
  const [activeTabSize, setActiveTabSize] = useState({
    width: "0px",
    height: "0px",
    top: "0px",
    left: "0px",
  });
  const tabRefs = useRef([]);

  const tabCount = tabs.length;
  const activeIndex = tabs.findIndex((tab) => tab.id === value); // Get the index of the active tab

  useEffect(() => {
    if (tabRefs.current[activeIndex]) {
      const tabElement = tabRefs.current[activeIndex];
      // Update the size and position of the sliding background based on the active tab's dimensions
      setActiveTabSize({
        width: `${tabElement.offsetWidth}px`, // Width of the active tab
        height: `${tabElement.offsetHeight}px`, // Height of the active tab
        top: `${tabElement.offsetTop}px`, // Top position of the active tab
        left: `${tabElement.offsetLeft}px`, // Left position of the active tab
      });
    }
  }, [activeIndex]);

  return (
    <StyledTabsContainer>
      {/* Sliding background effect */}
      <SlidingBackground
        top={activeTabSize.top}
        left={activeTabSize.left}
        width={activeTabSize.width}
        height={activeTabSize.height}
      />

      {tabs.map((tab, index) => (
        <StyledTab
          key={tab.id}
          isFocused={value === tab.id}
          onClick={() => onChange(tab.id, index)} 
          ref={(el) => tabRefs.current[index] = el} // Assign ref to each tab
        >
          <button>{tab.label}</button>
        </StyledTab>
      ))}
    </StyledTabsContainer>
  );
};

export default Tabs;
